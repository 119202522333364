import styles from "./summary_modal.module.scss";
import { VoucherCart } from "../voucher_edit/voucher";
import { toRupees } from "../../utils/number";
import { classNames } from "../../utils/etc";
import { Match, Show, Switch, createSignal } from "solid-js";
import { ButtonRegular } from "../../widgets/button";
import {
  goBack,
  HubbleRoute,
  popUntilHome,
  pushPage,
} from "../../shared_states/modal";
import {
  PaymentOptions,
  VoucherGenLoader,
  VoucherGenerationFailed,
} from "../payment_options/payment_options";
import { snackApiCallWrapper } from "../../shared_states/snackbar";
import { initPgRedemption } from "../../data/products";
import { RedemptionTransactions } from "../transactions/transactions";
import { TransactionDetail } from "../transactions/transaction_detail";
import { VoucherGenSuccess } from "../payment_options/voucher_gen_success";
import { sdkEventManager, HubbleEvent } from "../../data/events";
import { useClassicContextData } from "~/data/classic_context";
import {
  getClientIdFromCookie,
  getCoinLabel,
  getCoinLabelCapitalized,
  getCoinLabelCapitalizedPluralized,
} from "~/utils/common";
import { defaultCoin } from "~/assets/assets";
import { config } from "~/data/config";

type SummaryModalProps =
  | {
      cart: VoucherCart;
      showUsingCoins: false;
    }
  | {
      cart: VoucherCart;
      showUsingCoins: true;
      coinLabel: string;
    };

function SummaryModalHeader(props: SummaryModalProps) {
  const dryRunTransaction = () =>
    props.cart.dryRunTransaction!.find((r) => r.paymentMode === "UPI")!;

  const context = useClassicContextData();

  return (
    <>
      <img
        src={props.cart.voucherProduct!.voucherProductMetadata.logoUrl}
        class={styles.brandLogo}
        alt="brand logo"
      />
      <span class={styles.brandCardWorth}>
        {toRupees(props.cart.amount!)} brand card
      </span>
      <Switch>
        <Match
          when={
            context.isLoggedIn &&
            config.donationPartner.includes(
              context.clientConfig.partnerName ?? "no-client"
            )
          }
        >
          <span class={styles.shortSummaryText}>
            {toRupees(
              dryRunTransaction().redemptionAmount -
                dryRunTransaction().requiredDeposit
            )}
            &nbsp;
            <span>{"will be donated "}</span>
            <span>
              {" "}
              ({dryRunTransaction().discountDetails.totalDiscount.percentage}%
              off)
            </span>
            <span>{" on card purchase"}</span>
          </span>
        </Match>
        <Match when={true}>
          <span class={styles.shortSummaryText}>
            Getting for {toRupees(dryRunTransaction().requiredDeposit)}
            &nbsp;
            <span
              class={styles.redemptionAmount}
              style={{
                // keep the font size same as rest of the content if using coins label is shown
                "font-size": props.showUsingCoins ? "19px" : "15px",
              }}
            >
              {toRupees(dryRunTransaction().redemptionAmount)}
            </span>{" "}
            {props.showUsingCoins ? (
              <span>using {props.coinLabel}</span>
            ) : (
              <span>
                {" "}
                ({dryRunTransaction().discountDetails.totalDiscount.percentage}%
                off)
              </span>
            )}
          </span>
        </Match>
      </Switch>
    </>
  );
}

export function SummaryModal(props: { cart: VoucherCart }) {
  const context = useClassicContextData();

  const dryRunTransaction = () =>
    props.cart.dryRunTransaction!.find((r) => r.paymentMode === "UPI")!;
  const discountDetails = () => dryRunTransaction().discountDetails;
  const totalRequiredCoins = () => dryRunTransaction().totalRequiredCoins;
  const coinToInrConversionRatio = context.isLoggedIn
    ? context.clientConfig.coinToInrConversionRatio ?? 1
    : 1;
  const coinIconUrl = context.isLoggedIn
    ? context.clientConfig.coinIconUrl
    : defaultCoin;

  const [isGenerating, setIsGenerating] = createSignal(false);

  const useCoinsToFundBaseDiscount = context.isLoggedIn
    ? context.clientConfig.useCoinsToFundBaseDiscount
    : false;

  const clientId =
    context.isLoggedIn && context.mode == "sdk"
      ? context.initParams.clientId
      : "hubble";

  return (
    <div class={styles.summaryModal}>
      <SummaryModalHeader
        cart={props.cart}
        showUsingCoins={useCoinsToFundBaseDiscount}
        coinLabel={getCoinLabelCapitalizedPluralized(clientId)}
      />
      <div class={styles.summaryItems}>
        <div class={styles.heading}>Summary</div>
        <SummaryItem
          class={styles.brandCard}
          label="Brand card"
          value={toRupees(dryRunTransaction().redemptionAmount)}
        />
        <Switch>
          <Match
            when={
              useCoinsToFundBaseDiscount &&
              discountDetails().hubbleBaseDiscount.amount > 0
            }
          >
            <SummaryItemRichCoinConsumption
              clientId={clientId}
              coinIconUrl={coinIconUrl}
              discountAmount={discountDetails().hubbleBaseDiscount.amount}
              totalRequiredCoins={totalRequiredCoins()}
              coinToInrConversionRatio={coinToInrConversionRatio}
            />
          </Match>
          <Match when={discountDetails().hubbleBaseDiscount.amount > 0}>
            <SummaryItem
              class={styles.hubbleDiscount}
              label={`Hubble discount (${discountDetails().hubbleBaseDiscount.percentage}%)`}
              value={toRupees(discountDetails().hubbleBaseDiscount.amount)}
            />
          </Match>
        </Switch>

        <Show
          when={dryRunTransaction().discountDetails.couponDiscount.amount > 0}
        >
          <SummaryItem
            class={styles.coupon}
            label={`"${props.cart.couponCode}" applied (${dryRunTransaction().discountDetails.couponDiscount.percentage}%)`}
            value={
              "-" +
              toRupees(
                dryRunTransaction().discountDetails.couponDiscount.amount
              )
            }
          />
        </Show>

        <Show when={discountDetails().coinDiscount.amount > 0}>
          <SummaryItem
            class={styles.coupon}
            label={`Coin discount (${discountDetails().coinDiscount.percentage}%)`}
            value={"-" + toRupees(discountDetails().coinDiscount.amount)}
          />
        </Show>
      </div>
      <Show when={dryRunTransaction().dueAmount > 0}>
        <SummaryItem
          class={styles.totalPayable}
          label="You pay only"
          value={toRupees(dryRunTransaction().dueAmount)}
        />
      </Show>

      <Switch>
        <Match when={dryRunTransaction().dueAmount > 0}>
          <ButtonRegular
            onClick={() => {
              goBack(() => {
                pushPage(
                  () => <PaymentOptions cart={props.cart} />,
                  undefined,
                  HubbleRoute.PAYMENT_OPTIONS
                );
              });
            }}
            isEnabled={true}
            class={styles.continueButton}
          >
            Pay {toRupees(dryRunTransaction().dueAmount)}
          </ButtonRegular>
        </Match>
        <Match when={true}>
          <ButtonRegular
            isLoading={isGenerating()}
            onClick={async () => {
              setIsGenerating(true);
              const res = await snackApiCallWrapper(
                initPgRedemption({
                  amount: dryRunTransaction().redemptionAmount,
                  paymentMode: dryRunTransaction().paymentMode,
                  productId: props.cart.voucherProductId,
                  denominationDetails: props.cart.denominations?.map((d) => {
                    return {
                      denomination: d.denomination,
                      quantity: d.quantity,
                    };
                  }),
                  variantId: props.cart.variantId,
                  consumeCoins: props.cart.useCoins,
                  couponCode: props.cart.couponCode,
                })
              );

              setIsGenerating(false);
              sdkEventManager.sendEvent(HubbleEvent.COIN_PAYMENT, {
                amount: dryRunTransaction().redemptionAmount,
                coins: totalRequiredCoins(),
                baseDiscount: discountDetails().hubbleBaseDiscount.amount,
                couponDiscount: discountDetails().couponDiscount.amount,
                totalDiscount: discountDetails().totalDiscount.amount,
              });
              goBack(() => {
                pushPage(
                  () => (
                    <VoucherGenLoader
                      onVoucherGenerated={async (order) => {
                        if (order.status == "COMPLETED") {
                          await popUntilHome();
                          pushPage(
                            () => <RedemptionTransactions />,
                            undefined,
                            HubbleRoute.TRANSACTIONS
                          );
                          pushPage(
                            () => <TransactionDetail transactionId={res.id} />,
                            undefined,
                            HubbleRoute.TRANSACTION_L2
                          );
                          pushPage(
                            () => (
                              <VoucherGenSuccess
                                orderId={order.id}
                                orderDetails={order}
                                onClose={() => goBack()}
                                refreshProfile={true}
                              />
                            ),
                            undefined,
                            HubbleRoute.VOUCHER_GENERATION_SUCCESS
                          );
                        }

                        if (order.status == "FAILED") {
                          goBack(() => {
                            pushPage(
                              () => <VoucherGenerationFailed />,
                              undefined,
                              HubbleRoute.VOUCHER_GENERATION_FAIL
                            );
                          });
                        }
                      }}
                      orderId={res.storeOrderDetails.id}
                    />
                  ),
                  undefined,
                  HubbleRoute.VOUCHER_GENERATION_LOADER
                );
              });
            }}
            isEnabled={true}
            class={styles.continueButton}
          >
            Get the card
          </ButtonRegular>
        </Match>
      </Switch>
    </div>
  );
}

function SummaryItem(props: { label: string; value: string; class?: string }) {
  return (
    <div class={classNames(styles.summaryItem, props.class)}>
      <span class={styles.summaryItemLabel}>{props.label}</span>
      <span class={styles.summaryItemValue}>{props.value}</span>
    </div>
  );
}

type SummaryItemRichCoinConsumptionProps = {
  clientId: string;
  coinIconUrl: string;
  discountAmount: number;
  totalRequiredCoins: number;
  coinToInrConversionRatio: number;
};

function SummaryItemRichCoinConsumption(
  props: SummaryItemRichCoinConsumptionProps
) {
  return (
    <div class="flex flex-row justify-between">
      <div class="flex flex-col gap-1">
        <div class="flex items-center text-normal text-successDark">
          {getCoinLabelCapitalized(props.clientId)} discount (
          <img class="inline h-5 w-5" src={props.coinIconUrl} alt="" />
          {props.totalRequiredCoins})
        </div>
        <div class="flex items-center gap-1 text-medium text-textNormal">
          <img class="inline h-5 w-5" src={props.coinIconUrl} alt="" />
          <span>
            {1 / props.coinToInrConversionRatio}{" "}
            {getCoinLabelCapitalizedPluralized(props.clientId)} = ₹1
          </span>
        </div>
      </div>
      <div class="text-normal text-successDark">
        -{toRupees(props.discountAmount)}
      </div>
    </div>
  );
}
