import { Match, Show, Switch } from "solid-js";
import { HubbleFullSvg } from "../../assets/assets";
import { classNames, getHexColorBrightness } from "../../utils/etc";
import { VoucherCart } from "./voucher";
import styles from "./voucher_card.module.scss";
import { VoucherProduct } from "~/server/types/brand";

export function VoucherCard(props: {
  product: VoucherProduct;
  class?: string;
  value: VoucherCart;
  showEditButton?: boolean;
  showCursor?: boolean;
  onEdit?: () => void;
}) {
  const backgroundColor =
    props.product.voucherProductMetadata.cardBackgroundColor;

  const textColor =
    getHexColorBrightness(backgroundColor) > 170 ? "black" : "white";

  return (
    <div
      class={classNames(styles.voucherCard, props.class)}
      style={{
        "background-color":
          props.product.voucherProductMetadata.cardBackgroundColor,
      }}
    >
      <div class={styles.gradientFlare}></div>

      <div class={styles.brandImage}>
        <img
          src={props.product.voucherProductMetadata.plainLogoUrl}
          class={styles.brandLogo}
        />
        <div class={styles.svgDots}>
          <DotSvg />
          <DotSvg />
          <DotSvg />
          <DotSvg />
          <DotSvg />
          <DotSvg />
        </div>
      </div>

      <div class={styles.hubbleBranding}>
        <HubbleFullSvg class={styles.hubbleIcon} />
      </div>

      <div class={styles.content} style={{ color: textColor }}>
        <span class={styles.cardWorthText}>{props.value.displayText}</span>
        <div
          class={styles.cardWorthValue}
          onClick={props.showEditButton ? props.onEdit : undefined}
        >
          <Switch>
            <Match when={props.value.voucherType == "denominations"}>
              <span>{props.value.amount}</span>
            </Match>

            <Match when={props.value.voucherType == "variant"}>
              <span>{props.value.displayValue}</span>
            </Match>

            <Match when={true}>
              <span>{props.value.amount}</span>
              <Show when={props.showCursor}>
                <span class={styles.blinkingCursor}></span>
              </Show>
            </Match>
          </Switch>

          <Show when={props.showEditButton}>
            <i class="ph ph-pencil-simple"></i>
          </Show>
        </div>
      </div>
    </div>
  );
}

export function VoucherCardPlain(props: {
  backgroundColor: string;
  class?: string;
  plainLogoUrl: string;
  displayText: string;
  value: string;
  onClick?: () => void;
}) {
  const backgroundColor = props.backgroundColor;

  const textColor =
    getHexColorBrightness(backgroundColor) > 170 ? "black" : "white";

  return (
    <div
      class={classNames(styles.voucherCard, props.class)}
      style={{ "background-color": backgroundColor }}
      onClick={props.onClick}
    >
      <div class={styles.gradientFlare}></div>

      <div class={styles.brandImage}>
        <img src={props.plainLogoUrl} class={styles.brandLogo} />
        <div class={styles.svgDots}>
          <DotSvg />
          <DotSvg />
          <DotSvg />
          <DotSvg />
          <DotSvg />
          <DotSvg />
        </div>
      </div>

      <div class={styles.hubbleBranding}>
        <HubbleFullSvg class={styles.hubbleIcon} />
      </div>

      <div class={styles.content} style={{ color: textColor }}>
        <span class={styles.cardWorthText}>{props.displayText}</span>
        <div class={styles.cardWorthValue}>
          <span>{props.value}</span>
        </div>
      </div>
    </div>
  );
}

function DotSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="5"
      viewBox="0 0 5 5"
      fill="none"
    >
      <circle cx="2.5" cy="2.5" r="2.5" fill-opacity="0.5" />
    </svg>
  );
}
