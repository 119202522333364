import { SDKInitParams } from "~/server/types/auth";
import { V4HomeResponse } from "~/data/v4_homepage";
import { ClientConfig } from "~/server/types/client";
import { createStore, SetStoreFunction } from "solid-js/store";
import { createContext, JSX, useContext } from "solid-js";
import { HomepageMetaData } from "~/server/types/brand";

export type LocalClientConfig = {
  showCategories: boolean;
  disableHomePageBackButton: boolean;
  consumeCoinsByDefault: boolean;
};

export type ClassicAppContextData =
  | {
      isLoggedIn: false;
      homepageMetadata: HomepageMetaData;
    }
  | {
      isLoggedIn: true;
      mode: "hubble";
      v4HomePage: V4HomeResponse;
      clientConfig: ClientConfig;
      homepageMetadata: HomepageMetaData;
    }
  | {
      isLoggedIn: true;
      mode: "sdk";
      initParams: SDKInitParams;
      v4HomePage: V4HomeResponse;
      clientConfig: ClientConfig;
      homepageMetadata: HomepageMetaData;
      localClientConfig: LocalClientConfig;
    };

type ClassicAppContext = {
  data: ClassicAppContextData;
  setData: SetStoreFunction<ClassicAppContextData>;
};

const ClassicAppContext = createContext<ClassicAppContext>();

type ClassicContextProviderProps = {
  children: JSX.Element;
  initialValue: ClassicAppContextData;
};

export function ClassicContextProvider(props: ClassicContextProviderProps) {
  const [store, setStore] = createStore(props.initialValue);

  return (
    <ClassicAppContext.Provider
      value={{
        data: store,
        setData: setStore,
      }}
    >
      {props.children}
    </ClassicAppContext.Provider>
  );
}

export function useClassicContextData() {
  const { data } = useContext(ClassicAppContext)!;
  return data;
}

export function useClassicContextUpdater() {
  const { setData } = useContext(ClassicAppContext)!;
  return setData;
}
