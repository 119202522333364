import { AuthMethod } from "~/server/types/client";

export type Optional<T> = T | null;

export type Array<T> = T[];

export type Env = "Staging" | "Preprod" | "Prod";

export type Mode = "hubble" | "sdk" | "rnr" | "squid";

export type BackendMode = Uppercase<Mode>;

export type BrandGroup = "occasion" | "recipient";

export enum Header {
  ContentType = "Content-Type",
  ClientId = "X-Client-Id",
  ClientSecret = "X-Client-Secret",
  ClientType = "X-Client-Type",
  AppVersion = "X-App-Version",
  SessionId = "X-Session-Id",
  RequestId = "X-Request-Id",
  DeviceId = "X-DEVICE-ID",
  DeviceInfo = "X-DEVICE-INFO",
  DeviceOs = "X-DEVICE-OS",
  HostOs = "X-HOST-OS",
  FpDeviceToken = "X-FP-DEVICE-TOKEN",
}

export enum Cookie {
  SessionId = "sid",
  ClientId = "clientId",
  Mode = "mode",
  FpjsRequestId = "fpjs-request-id",
  ClientSecret = "clientSecret",
}

export enum QueryParam {
  Action = "action",
  ClientId = "clientId",
  ClientSecret = "clientSecret",
  Token = "token",
  WrapperPlatform = "wrap-plt",
  Popup = "popup",
}

export enum Action {
  ClearSession = "clearSession",
}

declare module "@solidjs/start/server" {
  interface RequestEventLocals {
    sid?: string;
    clientId?: string;
    clientSecret?: string;
    mode?: Mode;
    logoUrl?: string;
    authMethod?: AuthMethod;
    partnerName?: string;
  }
}

export type FetchOptions = {
  isUnauthenticated?: boolean;
  throwAuthError?: boolean;
};

export type WrapperPlatform = "an" | "ios" | "rn" | null;

export type ThemeMode = "light" | "dark";
