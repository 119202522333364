import { toIndianNumber } from "~/utils/number";
import { CoinsTransactionsModal } from "../coins_transactions_modal";
import { useModal } from "../modal";
import { coinIcon } from "~/assets/assets";
import { Accessor, Setter, Show } from "solid-js";
import { RedeemCodeModal } from "../redeem_code_modal";
import { PhosphorIcon } from "~/widgets/icons";
import { Spacer } from "~/widgets/spacer";

export function CoinsPill(props: {
  latestCoinsBalance?: Accessor<number | undefined>;
  setRefreshCoins: Setter<boolean> | undefined;
  coins: Accessor<number> | undefined;
  setRefreshLatestBalance: Setter<boolean>;
  userName?: string;
  mode: string;
  isRedeemCodeEnabled?: boolean;
}) {
  const { openModal, setIsModalOn, updateModalContentGetter } = useModal()!;

  function openCoinsTransactionsModal(event: MouseEvent) {
    event.stopPropagation();
    updateModalContentGetter(() => {
      return (
        <CoinsTransactionsModal
          balance={props.latestCoinsBalance?.() ?? props.coins?.() ?? 0}
          userName={props.userName ?? ""}
          setRefreshCoins={
            props.setRefreshCoins ?? props.setRefreshLatestBalance
          }
          enableRedeemCode={props.isRedeemCodeEnabled ?? true}
          title={props.mode === "rnr" ? "Your coins" : undefined}
          subtitle={
            props.mode === "rnr"
              ? "Use it to get gift cards of your choice"
              : undefined
          }
        />
      );
    });

    setIsModalOn(true);
  }

  return (
    <div>
      <div
        onClick={openCoinsTransactionsModal}
        class={`darkTheme:bg-[#212427] darkTheme:border-[#313538] hidden cursor-pointer items-center rounded-[50px] border-[0.5px] border-[#C0C0C0] bg-baseTertiaryLight pl-2  lg:flex ${
          props.mode === "rnr" && (props.isRedeemCodeEnabled ?? true)
            ? "pr-[6px]"
            : "pr-3"
        }`}
        // style={
        //   {
        // "box-shadow": "0px 4px 6px 0px rgba(219, 221, 222, 0.2)",
        //   }
        // }
      >
        <div class="flex items-center justify-center gap-1 py-2">
          <img src={coinIcon} alt="" class="h-5 w-5" />
          <div
            class={`text-f12w700 text-textDark ${props.mode != "rnr" ? "pr-1" : ""}`}
          >
            {toIndianNumber(
              props.latestCoinsBalance?.() ?? props.coins?.() ?? 0
            )}
          </div>
        </div>
        <Show
          when={props.mode === "rnr" && (props.isRedeemCodeEnabled ?? true)}
        >
          <div
            class="flex cursor-pointer items-center rounded-e-2xl pl-1"
            onClick={() => {
              openModal(() => {
                return (
                  <RedeemCodeModal
                    setRefreshCoins={
                      props.setRefreshCoins ?? props.setRefreshLatestBalance
                    }
                    userName={props.userName ?? ""}
                  />
                );
              }, "md:w-[416px] h-fit");
            }}
          >
            <PhosphorIcon
              name="plus-circle"
              size="fill"
              fontSize={26}
              class="py-[4px]"
            />
          </div>
        </Show>
      </div>
      <div
        onClick={openCoinsTransactionsModal}
        class={`flex h-8 cursor-pointer items-center rounded-[50px] border-[0.5px] border-[#C0C0C0] bg-baseTertiaryLight py-[7px] pl-[8.4px]  lg:hidden ${props.mode === "rnr" && (props.isRedeemCodeEnabled ?? true) ? "pr-[6.4px]" : "pr-3"}`}
        style={{
          "box-shadow": "0px 2px 11px 0px rgba(0, 0, 0, 0.05)",
        }}
      >
        <img src={coinIcon} alt="" class="h-4 w-4" />
        <Spacer width={2.4} />
        <div class="text-f12w700 text-textDark">
          {toIndianNumber(props.latestCoinsBalance?.() ?? props.coins?.() ?? 0)}
        </div>
        <Show
          when={props.mode === "rnr" && (props.isRedeemCodeEnabled ?? true)}
        >
          <div
            class="flex cursor-pointer items-center rounded-e-2xl pl-1"
            onClick={() => {
              openModal(() => {
                return (
                  <RedeemCodeModal
                    setRefreshCoins={
                      props.setRefreshCoins ?? props.setRefreshLatestBalance
                    }
                    userName={props.userName ?? ""}
                  />
                );
              }, "md:w-[416px] h-fit");
            }}
          >
            <PhosphorIcon
              name="plus-circle"
              size="fill"
              fontSize={26}
              class="py-[4px]"
            />
          </div>
        </Show>
      </div>
    </div>
  );
}
