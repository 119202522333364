import { onCleanup, onMount } from "solid-js";

const useScript = (url: string, callback?: Function) => {
  onMount(() => {
    if (typeof window !== "undefined") {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      script.onload = () => {
        if (callback) {
          callback();
        }
      };

      onCleanup(() => {
        if (script) {
          document.head.removeChild(script);
        }
      });
    }
  });
};

export default useScript;
