import { pages, popPage, popWithAnimation } from "~/shared_states/modal";
import { closeSearchPage, isSearchVisible } from "~/screens/home/search";
import { routerLog } from "./logging";
import {
  closeIntercom,
  isIntercomVisible,
  setIsIntercomVisible,
} from "~/utils/third_party/intercom";

export default function attachPopStateListener() {
  window.onpopstate = popStateListener;
}

export function detachPopStateListener() {
  window.onpopstate = null;
  routerLog(`popstate listener disabled`);
}

export async function popStateListener() {
  routerLog(`browser back called. Pages are ${pages().map((p) => p.name)}`);

  if (isIntercomVisible()) {
    setIsIntercomVisible(false);
    closeIntercom();
    return;
  }

  if (pages().length > 1) {
    popWithAnimation();
  } else {
    routerLog(`Last page`);
    if (isSearchVisible()) {
      routerLog(`Search visible. Closing search`);
      closeSearchPage();
    } else {
      // pass close event to parent iframe
      // not relevant in Hubble mode
      routerLog(`posting a message for parent to close`);
      window.parent.postMessage(
        {
          type: "close",
        },
        "*"
      );

      try {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "action",
            action: "close",
          })
        );
      } catch (e) {}

      popPage();
    }
  }
}
