import {
  JSX,
  Match,
  Show,
  Switch,
  createEffect,
  createResource,
} from "solid-js";
import styles from "./resource_loader.module.scss";
import { BackButton, ButtonRegular, ThreeDotLoader } from "./button";

export function ResourceLoader<T>(props: {
  fetch: () => Promise<T>;
  children: (resource: T) => JSX.Element;
  onAfterFetch?: (resource: T) => void;
  showLoader?: boolean;
  retryCount?: number;
  loaderColor?: string;
  isFullPage?: boolean;
}) {
  const maxRetryCount = props.retryCount || 0;
  var retryCount = props.retryCount || 1;
  const [resource, resourceActions] = createResource(async () => {
    const result = await props.fetch();

    if (props.onAfterFetch) {
      props.onAfterFetch(result);
    }

    return result;
  });

  createEffect(() => {
    if (resource.state == "errored" && retryCount < maxRetryCount) {
      retryCount++;

      resourceActions.refetch();
    }
  });

  return (
    <Switch>
      <Match when={resource.state == "pending" && props.showLoader !== false}>
        <div class={styles.loading}>
          <Show when={props.isFullPage}>
            <BackButton />
          </Show>
          <ThreeDotLoader color={props.loaderColor ?? "#000"} />
        </div>
      </Match>
      <Match when={resource.state == "errored"}>
        <SimpleRetryView
          message={resource.error.message}
          onRetry={() => resourceActions.refetch()}
        />
      </Match>
      <Match when={resource.state == "ready"}>
        {props.children(resource()!)}
      </Match>
    </Switch>
  );
}

export function SimpleRetryView(props: {
  message: string;
  onRetry: () => void;
}) {
  return (
    <div class={styles.error}>
      <span class={styles.errorMessage}>{props.message}</span>
      <ButtonRegular onClick={props.onRetry} isEnabled={true}>
        Try again
      </ButtonRegular>
    </div>
  );
}
