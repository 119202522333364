import "./swipe_button.scss";
import { JSX, Match, onCleanup, onMount, Switch } from "solid-js";
import { PhosphorIcon } from "../icons";
import { ThreeDotLoader } from "../button";
import { createDebounce } from "~/utils/debounce";

const [isRunning, callDebounce] = createDebounce(300);

export function SwipeButton(props: {
  onSwipe?: () => void;
  isLoading: () => boolean;
  isEnabled: () => boolean;
  loaderColor?: string;
  isDarkTheme?: boolean;
  children?: JSX.Element;
  class?: string;
}) {
  onMount(() => {
    import("jquery")
      .then((module) => {
        return ((window as any).$ = module.default);
      })
      .then(() => {
        listenToMouseEvents();
      })
      .catch((err) => {
        alert(err);
      });
  });

  onCleanup(() => {
    try {
      if (window && (window as any).$) {
        (window as any).$("#slider").off("mousedown touchstart");
        (window as any).$("#slider").off("mouseup touchend");
        (window as any).$(document.body).off("mousemove touchmove");
      }
    } catch (error) {}
  });

  return (
    <div
      id="button-background"
      class="buttonBackground h-[48px] w-full"
      classList={{
        "bg-baseSecondaryMedium": !props.isEnabled(),
        "bg-basePrimaryDark dark:bg-baseTertiaryLight": props.isEnabled(),
        [props.class ?? ""]: true,
      }}
    >
      <Switch>
        <Match when={props.isLoading()}>
          <ThreeDotLoader color={props.loaderColor ?? "fff"} />
        </Match>
        <Match when={true}>
          <span
            id="slide-text"
            class="slideText"
            classList={{
              darkShimmer: props.isEnabled() && props.isDarkTheme,
              shimmer: props.isEnabled() && !props.isDarkTheme,
            }}
          >
            {props.children ?? "Swipe to get this card"}
          </span>
          <div
            id="slider"
            class={`slider bg-baseTertiaryLight dark:bg-baseDark`}
          >
            <PhosphorIcon
              name="caret-right"
              size="bold"
              fontSize={14}
              class="text-baseDark dark:text-baseTertiaryLight"
            />
          </div>
        </Match>
      </Switch>
    </div>
  );

  function listenToMouseEvents() {
    let initialMouse = 0;
    let slideMovementTotal = 0;
    let mouseIsDown = false;
    let slider = $("#slider");

    function animateToInitialState() {
      $("#slide-text").fadeTo(300, 1);
      slider.animate(
        {
          left: "6px",
        },
        300
      );
    }

    slider.on("mousedown touchstart", function (event: any) {
      if (!props.isEnabled()) return;
      const buttonBgWidth = $("#button-background").width() ?? 0;
      const sliderWidth = slider.width() ?? 0;
      mouseIsDown = true;
      slideMovementTotal = buttonBgWidth - sliderWidth - 10;
      initialMouse = event.clientX || event.originalEvent.touches[0].pageX;
    });

    slider.on("mouseup touchend", function (event: any) {
      if (!props.isEnabled()) return;

      if (!mouseIsDown) return;
      mouseIsDown = false;
      const currentMouse = event.clientX || event.changedTouches[0].pageX;
      const relativeMouse = currentMouse - initialMouse;

      if (relativeMouse < slideMovementTotal) {
        animateToInitialState();
        return;
      }

      setTimeout(function () {
        animateToInitialState();
      }, 300);

      // slide complete
      callDebounce({
        callback: async () => {
          props.onSwipe?.();
        },
      });
      setTimeout(function () {
        slider.on("click tap", function (event: any) {
          slider.off("click tap");
        });
      }, 0);
    });

    $(document.body).on("mousemove touchmove", function (event: any) {
      if (!props.isEnabled()) return;

      if (!mouseIsDown) return;

      const currentMouse =
        event.clientX || event.originalEvent.touches[0].pageX;
      const relativeMouse = currentMouse - initialMouse;
      const slidePercent = 1 - relativeMouse / slideMovementTotal;

      $("#slide-text").fadeTo(0, slidePercent);

      if (relativeMouse <= 0) {
        slider.css({ left: "-10px" });
        return;
      }
      if (relativeMouse >= slideMovementTotal + 10) {
        slider.css({ left: slideMovementTotal + "px" });
        return;
      }
      slider.css({ left: relativeMouse - 10 });
      event.preventDefault();
      event.stopPropagation();
    });
  }
}
