import { PhosphorIcon } from "./icons";

type UseOnBrandButtonProps = {
  deeplink: string;
  brandName: string;
  copyDataToClipboard?: () => Promise<void>;
};

export function UseOnBrandButton(props: UseOnBrandButtonProps) {
  return (
    <div
      class="darkTheme:bg-baseDark inline-flex h-11 w-full cursor-pointer items-center justify-center gap-2 rounded-[68px] bg-neutral-800"
      onClick={async () => {
        if (props.copyDataToClipboard) {
          await props.copyDataToClipboard();
        }
        console.log(`opening the deeplink in new tab. Link ${props.deeplink} `);
        const newTab = window.open(props.deeplink, "_blank");
        if (newTab) {
          newTab.focus();
        } else {
          console.error("Failed to open the new tab.");
        }
      }}
    >
      <div class="text-center text-[14px] font-semibold text-baseTertiaryLight">
        Use on {props.brandName}
      </div>
      <div class="mt-[3px]">
        <PhosphorIcon
          name="arrow-up-right"
          class="text-baseTertiaryLight	"
          fontSize={20}
        />
      </div>
    </div>
  );
}
