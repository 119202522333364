import { Accessor, JSX, Match, Setter, Show, Switch } from "solid-js";
import { useModal } from "../modal";
import { RedeemCodeModal } from "../redeem_code_modal";
import { PhosphorIcon } from "~/widgets/icons";
import { CoinsPill } from "./coins_pill";
import { BrandingRow, HubbleBranding } from "~/widgets/branding";

export function CoinsPillOrBranding(props: {
  mode: string;
  showCoins?: Accessor<boolean> | undefined;
  setRefreshCoins: Setter<boolean> | undefined;
  setRefreshLatestBalance: Setter<boolean>;
  userName?: string;
  latestCoinsBalance: Accessor<number | undefined>;
  coins: Accessor<number> | undefined;
  isRedeemCodeEnabled?: boolean;
  showBranding?: boolean;
  showVerticalBranding?: boolean;
  isClientAxis?: boolean;
  buildBranding?: () => JSX.Element;
}) {
  const { openModal } = useModal()!;

  return (
    <div class="flex flex-row items-center gap-2">
      <Show when={props.mode == "rnr" && !(props.showCoins?.() ?? true)}>
        <Switch>
          <Match when={props.showBranding}>
            {props.buildBranding?.() ?? (
              <Show
                when={props.showVerticalBranding}
                fallback={<BrandingRow />}
              >
                <HubbleBranding
                  verticalOrientation={props.showVerticalBranding}
                  class={`w-fit border-0 ${props.isClientAxis ? "pt-2" : ""}`}
                />
              </Show>
            )}
          </Match>
          <Match when={true}>
            <button
              class={`flex h-8 w-fit flex-col items-center justify-center rounded-full bg-baseDark px-3 py-3 text-f12Bold text-baseTertiaryLight lg:h-9 lg:text-mediumBold`}
              onClick={() => {
                openModal(() => {
                  return (
                    <RedeemCodeModal
                      setRefreshCoins={
                        props.setRefreshCoins ?? props.setRefreshLatestBalance
                      }
                      userName={props.userName ?? ""}
                    />
                  );
                }, "md:w-[416px] h-fit");
              }}
            >
              <div class="flex flex-row items-center justify-center">
                <PhosphorIcon
                  name="plus"
                  fontSize={14}
                  class="pr-1"
                  size={"bold"}
                />
                <div>Redeem code</div>
              </div>
            </button>
          </Match>
        </Switch>
      </Show>
      <Switch>
        <Match when={props.showCoins?.() ?? true}>
          <CoinsPill
            latestCoinsBalance={props.latestCoinsBalance}
            setRefreshCoins={props.setRefreshCoins}
            setRefreshLatestBalance={props.setRefreshLatestBalance}
            userName={props.userName}
            mode={props.mode}
            coins={props.coins}
            isRedeemCodeEnabled={props.isRedeemCodeEnabled}
          />
        </Match>
      </Switch>
    </div>
  );
}
