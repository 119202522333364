import { Component } from "solid-js";
import {
  CurrentPage,
  goBack,
  HubbleRoute,
  pushPage,
} from "../shared_states/modal";
import VoucherL2 from "./vocher_l2/voucher_l2";
import { openVoucherGen } from "./voucher_edit/voucher_edit";
import { trie } from "./builder";
import { VoucherCart } from "./voucher_edit/voucher";
import BrandUnavailableModal from "./brand_unavailable_modal";
import { HubbleBrandProduct } from "~/data/products";

function isDesktop(): boolean {
  return window.innerWidth >= 768;
}

export function openUnavailableBrandModal(props: {
  product: HubbleBrandProduct;
}) {
  pushPage(
    () =>
      BrandUnavailableModal({
        brandName: props.product.voucherProduct.title,
        brandLogoUrl: props.product.voucherProduct.iconImageUrl,
      }),
    {
      isModal: true,
    },
    HubbleRoute.BRAND_UNAVAILABLE
  );
}

export function openVoucherL2(props: { productId: string }) {
  // if (isDesktop()) {
  //   pushPage(() => <AmazonDesktopL2 productId={props.productId} />);
  //   return;
  // }

  openVoucherGen({
    productId: props.productId,
    onContinue: async (cart, voucher) => {
      goBack(() => {
        pushPage(
          function ({ page }) {
            return (
              <VoucherL2
                productId={props.productId}
                cart={(page?.componentProps as VoucherCart) || cart}
                setPageComponentProps={(newProps) => {
                  (page as CurrentPage).componentProps = newProps;
                }}
              />
            );
          },
          {
            path: `/products/${props.productId}`,
            eventProps: {
              productId: props.productId,
              brandName: voucher.name,
            },
          },
          HubbleRoute.BRAND_L2_SCREEN
        );
      });
    },
  });
}
