import { hubbleFetch } from "../utils/fetch";
import { defaultHeaders } from "./auth";

export const brandsCount = 400;

export function getStaticJson(key: string) {
  return fetch(`https://app.myhubble.money/ssr/${key}`, {
    cache: "no-cache",
  }).then((response) => response.json());
}

export function getMarkdown(key: string) {
  return fetch(`https://renderer.myhubble.money/v3/api/static/${key}`, {}).then(
    (response) => {
      if (!response.ok) {
        throw new Error(response.statusText || "Failed to fetch markdown");
      }
      return response.text();
    }
  );
}

export function getJson<T>(key: string) {
  return fetch(`https://renderer.myhubble.money/v3/api/static/${key}`, {}).then(
    (response) => response.json() as T
  );
}
