import {
  createEffect,
  createSignal,
  JSX,
  onCleanup,
  onMount,
  Show,
} from "solid-js";
import { ThreeDotLoader } from "./button";
import { zIndexes } from "~/z_indexes";

export default function InfiniteScroll(props: {
  children: JSX.Element;
  hasMore: boolean;
  next: () => void;
  scrollTreshold?: number;
}) {
  let observerRef: any;

  const [loading, setLoading] = createSignal(false);

  onMount(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (props.hasMore) {
          setLoading(true);
          props.next();
        }
      } else {
        setLoading(false);
      }
    });

    observer.observe(observerRef);

    onCleanup(() => {
      observer.unobserve(observerRef);
    });
  });

  const observerObjectHeight = !props.scrollTreshold
    ? "100px"
    : props.scrollTreshold + "px";

  createEffect(() => {
    if (!props.hasMore) {
      setLoading(false);
    }
  });

  return (
    <>
      {props.children}
      <div
        ref={observerRef}
        style={{
          height: observerObjectHeight,
          "margin-top": "-" + observerObjectHeight,
          "z-index": zIndexes.infiniteScroll,
        }}
      />
      <Show when={loading()}>
        <div class="mb-16 flex w-full flex-row justify-center">
          <ThreeDotLoader color="#000" />
        </div>
      </Show>
    </>
  );
}
