import { For } from "solid-js";
import { makeUPIAppRequest } from "../../data/products";
import { rewriteURL, upiApps } from "../../data/upi_apps";
import { classNames } from "../../utils/etc";
import { ButtonRegular } from "../../widgets/button";
import styles from "./upi_apps.module.scss";
import { PhosphorIcon } from "~/widgets/icons";

export function UpiApps(props: {
  orderId: string;
  amount: number;
  onAfterClick?: () => void;
}) {
  const assets = Object.values(upiApps).map((app) => {
    return {
      asset: app.icon,
      name: app.name,
      backendName: app.backendName,
      app: app,
      class: app.noBackground
        ? classNames(styles.upiApp, styles.noBackground)
        : styles.upiApp,
    };
  });

  return (
    <div class={styles.upiAppsModal}>
      <div class={styles.upiApps}>
        <For each={assets}>
          {(asset) => (
            <div
              class={asset.class}
              onClick={async () => {
                if (props.onAfterClick) {
                  props.onAfterClick();
                }

                const order = await makeUPIAppRequest(
                  props.orderId,
                  asset.backendName
                );

                const url = rewriteURL(order.link, asset.app);

                let loc = window.top?.location;
                if (loc) {
                  loc.href = url;
                } else {
                  window.location.href = url;
                }
              }}
            >
              <img src={asset.asset} class={styles.image} />
              <span class={styles.appName}>{asset.name}</span>
            </div>
          )}
        </For>
      </div>
    </div>
  );
}

export function PayWithUpiButton(props: {
  orderId: string;
  onAfterClick?: () => void;
}) {
  return (
    <div>
      <ButtonRegular
        isEnabled={true}
        onClick={async () => {
          if (props.onAfterClick) {
            props.onAfterClick();
          }

          const order = await makeUPIAppRequest(props.orderId, "PAYTM");
          let loc = window.top?.location;
          if (loc) {
            loc.href = order.link;
          } else {
            window.location.href = order.link;
          }
        }}
        class={styles.payWithUpiButton}
      >
        <span>Select UPI app to pay</span>
        <PhosphorIcon name="caret-right" fontSize={16} />
      </ButtonRegular>
    </div>
  );
}
