import { classNames } from "~/utils/etc";
import styles from "~/screens/vocher_l2/voucher_l2.module.scss";
import { Match, Show, Switch, useContext } from "solid-js";
import { toIndianNumber, toRupees } from "~/utils/number";
import { SimulatedTransactionDetails } from "~/server/types/brand";
import { useClassicContextData } from "~/data/classic_context";
import { config } from "~/data/config";
import { getClientIdFromCookie } from "~/utils/common";

type CoinStripProps = {
  isCoinsOnly: boolean;
  coinIconUrl: string;
  primaryTransaction: () => SimulatedTransactionDetails;
};

export function TotalAndDiscountedAmount(props: CoinStripProps) {
  const context = useClassicContextData();

  const v4Home = context.isLoggedIn ? context.v4HomePage : null;

  const { isCoinsOnly, coinIconUrl, primaryTransaction } = props;

  return (
    <div
      class={classNames(
        styles.offerTexts,
        isCoinsOnly ? styles.coins : styles.normal
      )}
    >
      <Switch>
        <Match when={isCoinsOnly}>
          <span class={styles.getForDiscountText}>Get now using</span>
          <span class={classNames(styles.effectivePrice, styles.coins)}>
            <img src={coinIconUrl} class={styles.coinIcon} />{" "}
            {toIndianNumber(primaryTransaction().totalRequiredCoins)}
          </span>
          <Show
            when={
              context.isLoggedIn && primaryTransaction().requiredDeposit == 0
            }
          >
            <span class={styles.coinBalance}>
              Balance: {toIndianNumber(v4Home!.coins.totalAvailable)}
            </span>
          </Show>
        </Match>
        <Match
          when={
            context.isLoggedIn &&
            config.donationPartner.includes(
              context.clientConfig.partnerName ?? "no-client"
            )
          }
        >
          <span class={styles.getForDiscountText}>
            Donating{" "}
            {primaryTransaction().discountDetails.totalDiscount.percentage}%
          </span>
          <span class={styles.effectivePrice}>
            {toRupees(
              primaryTransaction().redemptionAmount -
                primaryTransaction().dueAmount
            )}
          </span>
        </Match>
        <Match when={true}>
          <span class={styles.getForDiscountText}>
            Get for{" "}
            {primaryTransaction().discountDetails.totalDiscount.percentage}%
            discount
          </span>
          <span class={styles.effectivePrice}>
            {toRupees(primaryTransaction().dueAmount)}
          </span>
          <span class={styles.actualPrice}>
            {toRupees(primaryTransaction().redemptionAmount)}
          </span>
        </Match>
      </Switch>
    </div>
  );
}
