import { createEffect, createSignal } from "solid-js";
import { classNames } from "../../utils/etc";
import styles from "./number_keyboard.module.scss";

export function NumberKeyboard(props: {
  value: number;
  class?: string;
  onChange: (value: number) => void;
}) {
  const [value, setValue] = createSignal<string>("");

  const fireEvent = () => {
    const number = parseFloat(value());
    props.onChange(Number.isNaN(number) ? 0 : number);
  };

  createEffect(() => {
    setValue(props.value.toString());
  });

  function handleNumberClick(number: string) {
    const newNumber = value() + number;
    // if text is a valid number, update the card worth
    const floatRegex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;

    if (floatRegex.test(newNumber)) {
      setValue(newNumber);
      fireEvent();
    }
  }

  function handleClearClick() {
    setValue("");
    fireEvent();
  }

  function handleBackspaceClick() {
    setValue(value().slice(0, -1));
    fireEvent();
  }

  function numberHandlerFactory(number: number) {
    const numString = number.toString();
    // (elem as HTMLElement).innerHTML = numString;
    // console.log(elem);
    return () => handleNumberClick(numString);
  }

  const onDblClick = (event: MouseEvent) => {
    event.preventDefault();
  };

  return (
    <div class={classNames(styles.numberKeyboard, props.class)}>
      <div
        class={styles.one}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(1)}
      >
        1
      </div>
      <div
        class={styles.two}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(2)}
      >
        2
      </div>
      <div
        class={styles.three}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(3)}
      >
        3
      </div>
      <div
        class={styles.four}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(4)}
      >
        4
      </div>
      <div
        class={styles.five}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(5)}
      >
        5
      </div>
      <div
        class={styles.six}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(6)}
      >
        6
      </div>
      <div
        class={styles.seven}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(7)}
      >
        7
      </div>
      <div
        class={styles.eight}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(8)}
      >
        8
      </div>
      <div
        class={styles.nine}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(9)}
      >
        9
      </div>
      <div
        class={styles.clear}
        onDblClick={onDblClick}
        onClick={handleClearClick}
      >
        Clear
      </div>
      <div
        class={styles.zero}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(0)}
      >
        0
      </div>
      <div
        class={styles.backspace}
        onDblClick={onDblClick}
        onClick={handleBackspaceClick}
      >
        <i class="ph-bold ph-backspace"></i>
      </div>
    </div>
  );
}
