import { ThreeDotLoader } from "./button";
import "./loader.css";

type DottedLoaderProps = {
  color?: string;
};

export function DottedLoader(props: DottedLoaderProps) {
  let c = "";
  if (props.color === undefined) {
    c = "!bg-baseDark";
  }
  return (
    <div class="left-0 top-0 z-20 flex h-screen w-full items-center justify-center">
      <ThreeDotLoader color={props.color ?? "#000"} class={c} />
    </div>
  );
}

type CircularLoaderProps = {
  size: number;
};

export function CircularLoader(props: CircularLoaderProps) {
  const { size } = props;

  return (
    <div
      class={`loader`}
      style={{
        height: `${size}px`,
        width: `${size}px`,
      }}
    ></div>
  );
}
