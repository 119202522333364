/// format date to Jun 1, 2024, 6:00 AM
export function formatDate(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
}

/// format date to 2023-06-13
export function formatDateNumeric(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    formatMatcher: "basic",
  };
  return date
    .toLocaleDateString("en-US", options)
    .split("/")
    .reverse()
    .join("-");
}

// format to 12 Jun 2024
export function formatDateToDDMMMMYYYY(date: Date) {
  return date.toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
}

// format date to 12 Jun’ 24
export function formatDateShort(date: Date) {
  return (
    date.toLocaleDateString("en-US", {
      day: "numeric",
    }) +
    " " +
    date.toLocaleDateString("en-US", {
      month: "short",
    }) +
    "’ " +
    date.toLocaleDateString("en-US", {
      year: "2-digit",
    })
  );
}

// format date to 12 Jun’ 24 · 4:00 PM
export function formateDateShortWithTime(date: Date) {
  return (
    formatDateShort(date) +
    " · " +
    date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    })
  );
}

// format date to 24 July 2025
export function formatDateLong(date: Date) {
  return (
    date.toLocaleDateString("en-US", {
      day: "numeric",
    }) +
    " " +
    date.toLocaleDateString("en-US", {
      month: "long",
    }) +
    " " +
    date.toLocaleDateString("en-US", {
      year: "numeric",
    })
  );
}

// format date to 24 July 2025 · 4:00 PM
export function formatDateLongWithTime(date: Date) {
  return (
    formatDateLong(date) +
    " · " +
    date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    })
  );
}

export function convertDateFormatFromYYYYMMDDToDDMMYYYY(dateString: string) {
  const [year, month, day] = dateString.split("-");
  return `${day}/${month}/${year}`;
}
