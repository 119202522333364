import { useClassicContextData } from "~/data/classic_context";
import { checkFilledGreen, defaultCoin } from "~/assets/assets";
import { toRupees } from "~/utils/number";
import { Accessor, createMemo, Match, Switch } from "solid-js";
import { PhosphorIcon } from "~/widgets/icons";
import { getCoinLabelCapitalizedPluralized } from "~/utils/common";
import { CircularProgressBar } from "~/widgets/circular_progress_bar";

type UseCoinsToFundBaseDiscountStripProps = {
  requiredCoins: Accessor<number>;
  discountAmount: Accessor<number>;
};

export function UseCoinsToFundBaseDiscountStrip(
  props: UseCoinsToFundBaseDiscountStripProps
) {
  const context = useClassicContextData();

  const requiredCoins = () => props.requiredCoins();
  const discountAmount = () => props.discountAmount();

  const v4Home = context.isLoggedIn ? context.v4HomePage : null;
  const clientConfig = context.isLoggedIn ? context.clientConfig : null;
  const clientId =
    context.isLoggedIn && context.mode == "sdk"
      ? context.initParams.clientId
      : "hubble";

  const availableCoins = v4Home?.coins.totalAvailable ?? 0;
  const canProceed = createMemo(() => availableCoins >= requiredCoins());

  return (
    <div
      class="mx-5 mb-5 flex flex-row items-center gap-2 rounded-xl border-[1px] border-solid px-4 py-2"
      style={{
        background: canProceed()
          ? "linear-gradient(90deg, rgba(15, 136, 73, 0.10) 2.73%, rgba(15, 136, 73, 0.00) 82.97%)"
          : "linear-gradient(90deg, rgba(187, 45, 19, 0.12) 2.8%, rgba(187, 45, 19, 0.00) 82.89%)",
        "border-color": canProceed() ? "#DDD" : "#BB2D13",
      }}
    >
      <Switch>
        <Match when={canProceed()}>
          <div class="h-6 w-6">
            <img src={clientConfig?.coinIconUrl ?? defaultCoin} alt="coins" />
          </div>
          <div class="flex flex-col">
            <div class="text-f12Bold text-successDark">
              Using {requiredCoins()}{" "}
              {getCoinLabelCapitalizedPluralized(clientId)} to avail{" "}
              {toRupees(discountAmount())} off
            </div>
            <div class="text- flex flex-row items-center gap-[2px] text-small text-textDark">
              <span>You have: </span>
              <img
                class="h-[14px] w-[14px]"
                src={clientConfig?.coinIconUrl ?? defaultCoin}
                alt="coins"
              />
              <span>{" " + availableCoins}</span>
            </div>
          </div>
          <div class="ml-auto h-5 w-5">
            <img src={checkFilledGreen} alt="checked" />
          </div>
        </Match>
        <Match when={true}>
          <PhosphorIcon name="warning" class="text-errorDark" fontSize={24} />
          <div class="flex flex-col">
            <div class="text-f12Bold text-errorDark">
              {requiredCoins()} {getCoinLabelCapitalizedPluralized(clientId)}{" "}
              needed to avail {toRupees(discountAmount())} off
            </div>
            <div class="text- flex flex-row items-center gap-[2px] text-small text-textDark">
              <span>You have: </span>
              <img
                class="h-[14px] w-[14px]"
                src={clientConfig?.coinIconUrl ?? defaultCoin}
                alt="coins"
              />
              <span>{" " + availableCoins}</span>
            </div>
          </div>
          <div class="ml-auto">
            <CircularProgressBar
              progressPercentage={(availableCoins / requiredCoins()) * 100}
            />
          </div>
        </Match>
      </Switch>
    </div>
  );
}
