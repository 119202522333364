import { Accessor, createEffect, createSignal } from "solid-js";

export class Debounce {
  private debounceTimer: any;
  private delay: number;
  constructor(delay: number) {
    this.delay = delay;
  }

  public debounce(callback: () => Promise<void>) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      callback();
    }, this.delay);
  }
}

export function createDebounce(
  delay: number
): [Accessor<boolean>, (callback: { callback: Function }) => void] {
  const [isRunning, setIsRunning] = createSignal(false);
  const [debounceCallback, setDebounceCallback] = createSignal<
    { callback: Function } | undefined | null
  >(null);

  const debounce = new Debounce(delay);

  createEffect(() => {
    const callback = debounceCallback();
    if (callback) {
      debounce.debounce(async () => {
        await callback.callback();
        setIsRunning(false);
      });
    }
  });

  return [
    isRunning,
    (callback) => {
      setIsRunning(true);
      setDebounceCallback(callback);
    },
  ];
}
