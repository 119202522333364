import {
  createEffect,
  createResource,
  createSignal,
  onCleanup,
} from "solid-js";
import style from "./payment_status_polling.module.scss";
import { Spacer } from "~/widgets/spacer";
import { ButtonRegular } from "~/widgets/button";
import { PaymentOrderStatus } from "~/server/types/order";
import { getPaymentOrder } from "~/data/products";
import { goBack } from "~/shared_states/modal";

export default function PaymentStatusPollingScreen({
  orderId,
  onPaymentComplete,
  onPaymentFailed,
}: {
  orderId: string;
  onPaymentComplete: () => void;
  onPaymentFailed: (failureReason: string) => void;
}) {
  const [time, setTime] = createSignal(5 * 60); // 5 minutes in seconds

  const [orderStatus, setOrderStatus] =
    createSignal<PaymentOrderStatus>("ACTIVE");

  const [order, manageOrder] = createResource(async () => {
    const order = await getPaymentOrder(orderId);
    if (orderStatus() != order.status) {
      setOrderStatus(order.status);
    }
    return order;
  }, {});

  const timer = setInterval(() => {
    setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : prevTime));
  }, 1000);

  const refreshInterval = setInterval(() => {
    if (!order.loading) {
      manageOrder.refetch();
    }
  }, 5000);

  onCleanup(_cleanup);

  function _cleanup() {
    clearInterval(timer);
    clearInterval(refreshInterval);
  }

  createEffect(() => {
    if (!order()) return;
    if (order.loading) return;
    if (order.error) return;

    if (order()?.status != "ACTIVE") {
      _cleanup();

      if (order()!.status == "PAID") {
        onPaymentComplete();
      }

      if (order()!.status == "FAILED") {
        onPaymentFailed(order()!.failureReason ?? "Payment failed");
      }
    }
  });

  return (
    <>
      <div class="flex grow flex-col items-center justify-center p-6">
        <div class="flex grow flex-col items-center justify-center">
          <div class="relative">
            <div class={style.loader}></div>
            <div class={style.loaderText}>{`${formatTime({
              time: time(),
            })}`}</div>
          </div>
          <Spacer height={32} />
          <p class="max-w-[70%] text-center text-normal text-textNormal">
            Please go to your UPI app and approve the payment request from
            Gullak Technologies.
          </p>
        </div>
        <ButtonRegular
          class="w-full"
          isEnabled={true}
          onClick={() => {
            goBack();
          }}
        >
          Stop transaction
        </ButtonRegular>
      </div>
    </>
  );
}

function formatTime({ time }: { time: number }) {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  const result = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  return result;
}
