type CircularProgressBarProps = {
  progressPercentage: number;
};

export function CircularProgressBar(props: CircularProgressBarProps) {
  const progressPerc = props.progressPercentage;
  const radius = 105;
  const circum = radius * 3.14 * 2;
  const progressBarDash = (progressPerc * circum) / 100;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 250 250"
      fill="none"
    >
      <circle
        class="bg"
        cx="125"
        cy="125"
        r="105"
        fill="none"
        stroke="#DDDDDD"
        stroke-width="30"
      ></circle>
      <circle
        class="fg"
        cx="125"
        cy="125"
        r="105"
        fill="none"
        stroke="#313538"
        stroke-linecap="round"
        stroke-width="30"
        stroke-dasharray={`${progressBarDash} ${circum - progressBarDash}`}
        transform="rotate(-90 125 125)"
      ></circle>
    </svg>
  );
}
