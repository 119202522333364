import {
  assetAmazonPayUPI,
  assetBhimUPI,
  assetCredUPI,
  assetGooglePayUPI,
  assetPaytmUPI,
  assetPhonePeUPI,
} from "../assets/assets";

export interface UPIApp {
  name: string;
  intentScheme?: string;
  intentHost?: string;
  intentPath?: string;
  deeplinkScheme?: string;
  deeplinkHost?: string;
  deeplinkPath?: string;
  androidPackageName: string;
  razorPayIntentName: string;
  icon?: string;
  circularIcon?: string;
  supportsIntentLaunch: boolean;
  backendName: string;
  type: string;
  noBackground?: boolean;
}

const phonePe = {
  name: "PhonePe",
  razorPayIntentName: "phonepe",
  intentScheme: "upi",
  deeplinkScheme: "phonepe",
  deeplinkHost: "upi",
  deeplinkPath: "pay",
  androidPackageName: "com.phonepe.app",
  backendName: "PHONEPE",
  icon: assetPhonePeUPI,
} as UPIApp;

const phonePeSimulator = {
  name: "PhonePe Simulator",
  razorPayIntentName: "",
  intentScheme: "upi",
  deeplinkScheme: "ppesim",
  deeplinkHost: "pay",
  androidPackageName: "com.phonepe.simulator",
  backendName: "PHONEPE",
  type: "PHONEPE_SIMULATOR",
  icon: assetPhonePeUPI,
} as UPIApp;

const payTM = {
  name: "PayTM",
  razorPayIntentName: "paytm",
  intentScheme: "upi",
  deeplinkScheme: "paytmmp",
  deeplinkHost: "upi",
  deeplinkPath: "pay",
  androidPackageName: "net.one97.paytm",
  backendName: "PAYTM",
  type: "PAYTM",
  icon: assetPaytmUPI,
  noBackground: true,
} as UPIApp;

const gpay = {
  name: "Google Pay",
  razorPayIntentName: "google_pay",
  intentScheme: "upi",
  deeplinkScheme: "tez",
  deeplinkHost: "upi",
  deeplinkPath: "pay",
  androidPackageName: "com.google.android.apps.nbu.paisa.user",
  backendName: "GPAY",
  type: "GPAY",
  icon: assetGooglePayUPI,
  noBackground: true,
} as UPIApp;

const bhim = {
  name: "BHIM",
  razorPayIntentName: "bhim",
  intentScheme: "upi",
  deeplinkScheme: "upi",
  deeplinkHost: "upi",
  deeplinkPath: "pay",
  androidPackageName: "in.org.npci.upiapp",
  backendName: "BHIM",
  type: "BHIM",
  icon: assetBhimUPI,
  noBackground: true,
} as UPIApp;

const cred = {
  name: "CRED",
  razorPayIntentName: "cred",
  intentScheme: "upi",
  deeplinkScheme: "credpay",
  deeplinkHost: "upi",
  deeplinkPath: "pay",
  androidPackageName: "com.dreamplug.androidapp",
  backendName: "CRED",
  type: "CRED",
  icon: assetCredUPI,
  noBackground: true,
} as UPIApp;

export const upiApps = {
  phonePe,
  payTM,
  gpay,
  bhim,
  cred,
};

/// We get upi://pay?...
/// we will have to change it to phonepe://upi/pay?...
export function rewriteURL(link: string, app: UPIApp) {
  const url = new URL(link);

  return new URL(
    `${app.deeplinkScheme}://${app.deeplinkHost}/${app.deeplinkPath}${url.search}`,
    url
  ).toString();
}
