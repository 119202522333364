import { config } from "~/data/config";

export const initSentry = () => {
  // @ts-ignore
  if (config.enableErrorLoggingToSentry && window.Sentry) {
    // @ts-ignore
    Sentry.init({
      dsn: "https://b55cabac068029a3a974e675519226fc@o1172589.ingest.us.sentry.io/4507057400053760",
      environment: config.env,
    });
  }
};

export const captureErrorInSentry = (err: Error) => {
  // @ts-ignore
  window.Sentry && Sentry.captureException(err);
};

export const attachUserIdToSentry = (userId: string) => {
  // @ts-ignore
  window.Sentry && Sentry.setUser({ userId });
};

export const clearUserFromSentry = () => {
  // @ts-ignore
  window.Sentry && Sentry.setUser(null);
};
