import { Divider } from "~/widgets/divider";
import { Faqs } from "~/components/brand_l2/faqs";
import { FAQ } from "~/data/static_faqs/types";
import { CloseModalButton, ModalInnerFrame } from "./modal";

export const faqs: FAQ[] = [
  {
    question: "What is a gift card?",
    answer:
      "Also known as brand vouchers, gift vouchers and brand cards, gift cards are a digital mode of payment which carries a amount along with a pin / card no. You can buy these gift cards and use them to pay on 300+ brand online and offline as well.",
  },
  {
    question: "Why should I use a gift card?",
    answer:
      "Gift cards help you get great value on your purchase with a brand. They simplify giving, help with budgeting, and are easily sent across distances, ensuring your thoughtful gesture brings joy and value.",
  },
  {
    question: "How to use a gift card?",
    answer:
      "To use a gift card, simply present it at the time of purchase in-store or enter the card’s unique code at checkout when shopping online. The amount of your purchase will be deducted from the card's balance.",
  },
];

export function AboutGiftCard() {
  return (
    <ModalInnerFrame>
      <div class="flex w-full flex-col gap-4 ">
        <div class="flex w-full flex-row items-start justify-between">
          <div class="text-h3 text-textDark">Learn about gift cards</div>
          <CloseModalButton />
        </div>

        <Divider bgColor="#EDEEF1" />
        <Faqs faqs={faqs} />
      </div>
    </ModalInnerFrame>
  );
}
