// @ts-nocheck
import { goBack } from "~/shared_states/modal";
import { getCookie } from "../client_cookie";
let isIntercomVisibile = false;

export function isIntercomVisible() {
  return isIntercomVisibile;
}

export function setIsIntercomVisible(value) {
  isIntercomVisibile = value;
}

export const openIntercom = (props?: Record<string, any>) => {
  if (window && window.Intercom) {
    window.Intercom("show");
  } else {
    const loaderDiv = document.createElement("div");
    loaderDiv.setAttribute(
      "style",
      `z-index: 2147483000; position:fixed; bottom: 20px; right: 20px; transform-origin: right bottom; height: 100px; min-height: 80px; width: 400px; max-height: 704px; box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px; border-radius: 16px; overflow: hidden; opacity: 1; transition: width 200ms ease 0s, height 200ms ease 0s, max-height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, opacity 83ms ease-out 0s;pointer-events: all; background-color: white; display: flex; justify-content: center; align-items: center`
    );
    loaderDiv.innerHTML = "<p>Loading support...</p>";
    document.body.appendChild(loaderDiv);

    const clientId = getCookie("clientId") || "";
    const mode = getCookie("mode") || "";

    window.intercomSettings = {
      hide_default_launcher: true, // set this to false, if you want to show the default launcher
      api_base: "https://api-iam.intercom.io",
      app_id: "h5n3qs5p",
      clientId: clientId,
      mode: mode,
      ...props,
      // user_id: user.id, // a UUID for your user
      // user_hash: "INSERT_HMAC_VALUE_HERE" // an Identity Verification user hash for your user
    };

    (async function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = false;
          s.src = "https://widget.intercom.io/widget/h5n3qs5p";
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
          l();
        } else if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }

      window.Intercom("boot", {
        clientId: clientId,
        mode: mode,
      });

      window.Intercom("onShow", function () {
        history.pushState({}, "", "");
        isIntercomVisibile = true;
      });
      window.Intercom("onHide", function () {
        if (isIntercomVisibile) {
          goBack();
        }
      });

      await new Promise((resolve) => setTimeout(resolve, 1000));
      window.Intercom("show");
      document.body.removeChild(loaderDiv);
    })();
  }
};

export function closeIntercom() {
  if (window && window.Intercom) {
    window.Intercom("hide");
  }
}
