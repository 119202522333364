import { Accessor, JSX, Setter, Show } from "solid-js";
import { zIndexes } from "~/z_indexes";
import { CoinsPillOrBranding } from "../brand_l2/coinspill_or_branding";
import { Mode } from "~/types";
import { PhosphorIcon } from "~/widgets/icons";
import { HeaderMenu } from "../header_menu";
import { A } from "@solidjs/router";
import { openIntercom } from "~/utils/third_party/intercom";
import { axisLogo, HubbleFullSvg } from "~/assets/assets";

type AxisHeaderProps = {
  userName?: string;
  mode: Mode;
  isLoggedIn: () => boolean;
  coins?: Accessor<number>;
  showCoins?: Accessor<boolean>;
  setRefreshCoins?: Setter<boolean>;
  isSearchActive?: Accessor<boolean>;
  setIsSearchActive?: Setter<boolean>;
  isDropdownOpen?: Accessor<boolean>;
  setIsDropdownOpen?: Setter<boolean>;
  latestCoinsBalance?: Accessor<number | undefined>;
  setRefreshLatestBalance?: Setter<boolean>;
  buildSearchBar?: () => JSX.Element;
};

export const AxisHeader = ({
  userName,
  isLoggedIn,
  mode,
  coins,
  showCoins,
  setRefreshCoins,
  isSearchActive,
  setIsSearchActive,
  isDropdownOpen,
  setIsDropdownOpen,
  latestCoinsBalance,
  setRefreshLatestBalance,
  buildSearchBar,
}: AxisHeaderProps) => {
  return (
    <div
      class={`${isLoggedIn() ? "sticky lg:h-[108px]" : "absolute h-[96px]"} top-0 w-full bg-baseTertiaryLight md:h-[96px]`}
      style={{
        "z-index": zIndexes.header,
      }}
    >
      <div class="mb-2 w-full bg-headerBGAxisSM bg-no-repeat [background-size:100%] [height:inherit] md:bg-headerBGAxisLG lg:bg-contain">
        <Show when={isSearchActive?.() ?? false}>
          <div
            class={` absolute bottom-0  left-0 right-0 w-full  bg-baseDark transition-all duration-300 md:top-28 ${isSearchActive?.() ? "opacity-40" : "pointer-events-none opacity-100"} ${isSearchActive?.() ? "top-32" : "top-20"} ${isDropdownOpen?.() ? "opacity-40" : "pointer-events-none opacity-0"}`}
          />
        </Show>
        <div class="absolute left-4 top-[1.6rem] md:hidden">
          <div
            class="cursor-pointer"
            onClick={() => {
              setIsDropdownOpen?.(!isDropdownOpen?.());
            }}
          >
            <Show
              when={isDropdownOpen?.()}
              fallback={
                <PhosphorIcon
                  name="list"
                  fontSize={28}
                  size={"bold"}
                  class="text-white"
                />
              }
            >
              <PhosphorIcon
                name="x"
                fontSize={28}
                size={"bold"}
                class="text-white"
              />
            </Show>
          </div>

          <HeaderMenu
            isDropdownOpen={isDropdownOpen!}
            setIsDropdownOpen={setIsDropdownOpen!}
            isLoggedIn={isLoggedIn()}
            mode={mode}
            isClientAxis={() => true}
          />
        </div>
        <A href="/" class="md:hidden">
          <img
            src={axisLogo}
            alt="axis bank logo"
            class="absolute left-12 top-6 h-[28px] cursor-pointer"
          />
        </A>
        <div class="hidden md:m-auto md:block md:max-w-[1136px]">
          <A href="/">
            <img
              src={axisLogo}
              alt="axis bank logo"
              class="absolute top-4 z-10 h-[40px] cursor-pointer   md:left-8 xl:left-auto xl:top-6"
            />
          </A>
          <div class="flex justify-end gap-5 py-1 md:mr-8 xl:mr-0 xl:py-3">
            <p
              class="cursor-pointer text-mediumBold text-white"
              onClick={() => {
                openIntercom();
              }}
            >
              Contact support
            </p>
            <A href="/axisbank/terms-and-conditions" target="_blank">
              <p class="cursor-pointer text-mediumBold text-white">
                Terms & conditions
              </p>
            </A>
            <Show when={isLoggedIn()}>
              <A href="/profile">
                <p class="cursor-pointer text-mediumBold text-white">
                  My gift cards
                </p>
              </A>
              <A href="/logout">
                <p class="cursor-pointer text-mediumBold text-white">Logout</p>
              </A>
            </Show>
          </div>
          <Show when={isLoggedIn()}>
            <div class="relative w-full md:pt-6 lg:pt-2">
              <div class="absolute left-1/2 -translate-x-1/2 transform">
                {buildSearchBar?.()}
              </div>
              <div
                class="mt-2 flex justify-end md:mr-8 lg:mr-auto"
                classList={{
                  "mt-1": showCoins?.() ?? false,
                }}
              >
                <CoinsPillOrBranding
                  mode={mode}
                  showCoins={showCoins}
                  userName={userName}
                  setRefreshCoins={setRefreshCoins}
                  setRefreshLatestBalance={setRefreshLatestBalance!}
                  latestCoinsBalance={latestCoinsBalance!}
                  isRedeemCodeEnabled={false}
                  coins={coins}
                  showBranding={true}
                  showVerticalBranding={true}
                  isClientAxis={true}
                  buildBranding={buildBranding}
                />
              </div>
            </div>
          </Show>
        </div>
        <Show when={isLoggedIn()}>
          <div class="block md:hidden">
            <div class="flex justify-end pr-4 pt-[50px]">
              <CoinsPillOrBranding
                mode={mode}
                showCoins={showCoins}
                userName={userName}
                setRefreshCoins={setRefreshLatestBalance}
                setRefreshLatestBalance={setRefreshLatestBalance!}
                latestCoinsBalance={latestCoinsBalance!}
                isRedeemCodeEnabled={false}
                coins={coins}
                showBranding={true}
                showVerticalBranding={false}
                isClientAxis={true}
                buildBranding={buildBranding}
              />
              <Show when={showCoins?.()}>
                <div
                  class="ml-2 flex h-8 w-8 items-center justify-center rounded-full border border-baseTertiaryDark align-middle"
                  onClick={() => {
                    setIsDropdownOpen?.(false);
                    setIsSearchActive?.(!isSearchActive?.());
                  }}
                >
                  <PhosphorIcon
                    name="magnifying-glass"
                    fontSize={14}
                    class="text-textNormal"
                    size="bold"
                  />
                </div>
              </Show>
            </div>

            <div class="mt-1 flex w-full flex-row items-center justify-center bg-baseTertiaryLight md:hidden">
              <Show when={isSearchActive?.()}>{buildSearchBar?.()}</Show>
            </div>
          </div>
        </Show>
      </div>
    </div>
  );

  function buildBranding() {
    return (
      <div class="flex flex-row items-center justify-center pt-4 sm:flex-col sm:items-end sm:pt-0">
        <span
          class={`font-[Inter] text-[10px] font-semibold text-[#7c7c7c] sm:text-[8.12px] `}
        >
          Powered by
        </span>
        <div class="w-[8px]" />
        <div class="h-[2px]" />
        <HubbleFullSvg class="h-[11.76px]" fill="#7c7c7c" />
      </div>
    );
  }
};
