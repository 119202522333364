export default class LocalStorageUtil {
  // Method to set data in localStorage with optional expiration time
  static setItem(key: string, value: any, durationInMinutes?: number): void {
    const now = new Date();

    const item = {
      value: value,
      expiry: durationInMinutes
        ? now.getTime() + durationInMinutes * 60000
        : null,
    };

    localStorage.setItem(key, JSON.stringify(item));
  }

  // Method to get data from localStorage
  static getItem(key: string): any | null {
    const itemStr = localStorage.getItem(key);

    // If the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // If the item has expired, remove it from localStorage and return null
    if (item.expiry && now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  }

  // Method to remove an item from localStorage
  static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  // Method to clear all items from localStorage
  static clear(): void {
    localStorage.clear();
  }
}

// // Example Usage
// // Set an item with a duration of 5 minutes
// LocalStorageUtil.setItem("name", "John Doe", 5);

// // Get the item
// const name = LocalStorageUtil.getItem("name");
// console.log(name); // Output: "John Doe" (if within 5 minutes)

// // Wait for more than 5 minutes, then try to get the item again
// // console.log(LocalStorageUtil.getItem("name")); // Output: null

// // Remove the item
// LocalStorageUtil.removeItem("name");

// // Clear all items
// LocalStorageUtil.clear();
