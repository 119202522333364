export function profileSvgIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="inherit" //
    >
      <circle cx="12.4156" cy="7.3062" r="3.66753" fill="inherit" />
      <path
        d="M18.8364 16.9687C18.8364 19.7733 15.9628 20.1426 12.4182 20.1426C8.87352 20.1426 6 19.7733 6 16.9687C6 14.1642 8.87352 11.8906 12.4182 11.8906C15.9628 11.8906 18.8364 14.1642 18.8364 16.9687Z"
        fill="inherit"
      />
    </svg>
  );
}

export function giftCardSvgIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="inherit"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.2288 19.4304H5.18983C4.03162 19.4304 3.08398 18.4036 3.08398 17.1486V8.02112C3.08398 6.7661 4.03162 5.73926 5.18983 5.73926H19.2288C20.387 5.73926 21.3347 6.7661 21.3347 8.02112V17.1486C21.3347 18.4036 20.387 19.4304 19.2288 19.4304Z"
        fill="inherit"
      />
      <path
        d="M12.668 13.7742C14.0831 13.7742 15.2303 12.3686 15.2303 10.6348L15.2328 10.6442C15.2328 12.378 16.38 13.7836 17.7951 13.7836"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.2309 9.68303C15.2309 8.10121 16.2775 6.81934 17.5682 6.81934C18.1239 6.81934 18.5744 7.29214 18.5744 7.87523C18.5744 9.14903 17.0767 10.1821 15.2296 10.1821C13.3825 10.1821 11.8848 9.14971 11.8848 7.87523C11.8848 7.29214 12.3353 6.81934 12.8916 6.81934C14.1824 6.81934 15.229 8.10121 15.229 9.68303"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.8703 18.9404V19.4404H15.8703V18.9404H14.8703ZM14.8703 5.74219V18.9404H15.8703V5.74219H14.8703Z"
        fill="inherit"
      />
      <path
        d="M21 11L3.56641 11.0078"
        stroke="black"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <circle cx="5.59784" cy="15.9992" r="0.58026" fill="inherit" />
      <circle cx="7.71307" cy="15.9992" r="0.58026" fill="inherit" />
      <circle cx="9.82831" cy="15.9992" r="0.58026" fill="inherit" />
    </svg>
  );
}

export function helpAndSupportSvgIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="inherit" //
    >
      <path
        d="M21.0037 12C21.0037 16.9726 16.9726 21.0037 12 21.0037"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.99609 11.9998C2.99609 7.02721 7.02721 2.99609 11.9998 2.99609"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 11.7058C3.00018 12.9121 3.24052 14.1087 3.70981 15.2399C3.92444 15.7653 4.52954 16.0782 5.14684 15.983L6.41095 15.784C7.43264 15.6232 8.12717 14.7678 7.98045 13.851L7.59999 11.4736C7.52633 11.0134 7.24667 10.5995 6.82497 10.3267C6.40328 10.0538 5.87564 9.94535 5.36274 10.0261L3.10601 10.3813"
        fill="inherit"
      />
      <path
        d="M3 11.7058C3.00018 12.9121 3.24052 14.1087 3.70981 15.2399C3.92444 15.7653 4.52954 16.0782 5.14684 15.983L6.41095 15.784C7.43264 15.6232 8.12717 14.7678 7.98045 13.851L7.59999 11.4736C7.52633 11.0134 7.24666 10.5995 6.82497 10.3267C6.40328 10.0538 5.87564 9.94535 5.36274 10.0261L3.10601 10.3813"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 11.7058C20.9998 12.9121 20.7595 14.1087 20.2902 15.2399C20.0756 15.7653 19.4705 16.0782 18.8532 15.983L17.5891 15.784C16.5674 15.6232 15.8728 14.7678 16.0195 13.851L16.4 11.4736C16.4737 11.0134 16.7533 10.5995 17.175 10.3267C17.5967 10.0538 18.1244 9.94535 18.6373 10.0261L20.894 10.3813"
        fill="inherit"
      />
      <path
        d="M21 11.7058C20.9998 12.9121 20.7595 14.1087 20.2902 15.2399C20.0756 15.7653 19.4705 16.0782 18.8532 15.983L17.5891 15.784C16.5674 15.6232 15.8728 14.7678 16.0195 13.851L16.4 11.4736C16.4737 11.0134 16.7533 10.5995 17.175 10.3267C17.5967 10.0538 18.1244 9.94535 18.6373 10.0261L20.894 10.3813"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0037 11.9998C21.0037 7.02721 16.9726 2.99609 12 2.99609"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.4924 8.49079C13.4894 8.49078 13.4866 8.4926 13.4855 8.49539C13.4843 8.49819 13.485 8.5014 13.4871 8.50354C13.4892 8.50568 13.4925 8.50632 13.4952 8.50517C13.498 8.50401 13.4999 8.50128 13.4999 8.49826C13.5 8.49625 13.4992 8.49427 13.4978 8.49284C13.4964 8.49142 13.4944 8.49067 13.4924 8.49079"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5061 8.4947C10.503 8.4947 10.5003 8.49652 10.4992 8.49931C10.498 8.50211 10.4986 8.50532 10.5008 8.50746C10.5029 8.50959 10.5061 8.51023 10.5089 8.50907C10.5117 8.50791 10.5135 8.50518 10.5135 8.50216C10.5137 8.50014 10.5129 8.49816 10.5115 8.49673C10.5101 8.4953 10.5081 8.49456 10.5061 8.4947"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 12.5C11 13 12.3 13.7 13.5 12.5"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function termsAndConditionsSvgIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="inherit" //
    >
      <circle cx="11.9998" cy="11.9998" r="9.00375" fill="inherit" />
      <path
        d="M11.9999 13.5003V11.9997C13.1049 11.9997 14.0007 11.1039 14.0007 9.99888C14.0007 8.89385 13.1049 7.99805 11.9999 7.99805C10.8948 7.99805 9.99902 8.89385 9.99902 9.99888"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0002 15.9521C11.9726 15.9521 11.9502 15.9745 11.9502 16.0022C11.9502 16.0298 11.9726 16.0522 12.0002 16.0522C12.0278 16.0522 12.0502 16.0298 12.0502 16.0022C12.0502 15.9745 12.0278 15.9521 12.0002 15.9521"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function logoutSvgIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="inherit" //
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.16667 16.16V6.25248C9.16667 5.67748 8.87083 5.14332 8.38333 4.83915L5.05 2.75582C3.94 2.06248 2.5 2.85998 2.5 4.16915V14.0758C2.5 14.6508 2.79583 15.185 3.28333 15.4892L6.61667 17.5725C7.72667 18.2667 9.16667 17.4683 9.16667 16.16Z"
        fill="inherit"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 9.16667H17.5"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.833 10.8333L17.4997 9.16667L15.833 7.5"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16699 15.833H12.5003C13.4212 15.833 14.167 15.0872 14.167 14.1663V13.333"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.167 5V4.16667C14.167 3.24583 13.4212 2.5 12.5003 2.5H4.16699"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
