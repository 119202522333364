import {
  brandUnavailableModalDecorationPlug,
  brandUnavailableModalDecorationRibbon,
} from "~/assets/assets";
import { goBack, popPage } from "~/shared_states/modal";
import { ButtonRegular } from "~/widgets/button";
import HubbleImage from "~/widgets/hubble_image";

export default function BrandUnavailableModal(props: {
  brandName: string;
  brandLogoUrl: string;
}) {
  return (
    <div class=" bg-baseTertiaryLight py-5 pt-6">
      <div class="flex flex-row items-center justify-between pb-4">
        <img
          src={brandUnavailableModalDecorationRibbon}
          alt={props.brandName}
          class="w-8 flex-grow"
        />
        <div class="relative flex-shrink-0 justify-center ">
          <div class="pb-4">
            <HubbleImage
              src={props.brandLogoUrl}
              alt={props.brandName}
              class="h-20 w-20 rounded-3xl"
            />
          </div>
          <div class="absolute -left-[10px] bottom-0 right-0 flex w-[100px] justify-center rounded-[14px] border-2 border-baseTertiaryLight bg-baseTertiaryDark px-4 py-1 text-f12Bold text-[#555555]">
            Unavailable
          </div>
        </div>
        <img
          src={brandUnavailableModalDecorationPlug}
          alt={props.brandName}
          class="w-8 flex-grow"
        />
      </div>
      <div class="px-5">
        <div class="px-2 pb-2 text-center text-h4 text-textDark">
          {props.brandName} is temporarily unavailable.
        </div>
        <div class="px-2 pb-6 text-center align-middle text-medium text-textNormal">
          We are working with the brand to make the card back live again. This
          usually takes approx 30 mins.
        </div>
        <ButtonRegular
          children={"Got it"}
          isEnabled={true}
          onClick={() => {
            goBack();
          }}
        ></ButtonRegular>
      </div>
    </div>
  );
}
