import { createSignal } from "solid-js";
import { SDKAuthData, SDKInitParams } from "~/server/types/auth";
import { ClientConfig } from "~/server/types/client";
import { Header } from "~/types";
import { defaultCoin } from "../assets/assets";
import { APIError, hubbleFetch } from "../utils/fetch";
import { config } from "./config";
import { EventManager, sdkEventManager } from "./events";
import { V4HomeResponse } from "./v4_homepage";
import { getOS } from "~/utils/platform";
import { newRequestId } from "~/utils/common";
import {
  attachUserIdToSentry,
  clearUserFromSentry,
} from "~/utils/third_party/sentry";

export const [auth, setAuth] = createSignal<SDKAuthData>({} as any);

export const [defaultHeaders, setDefaultHeaders] = createSignal<{
  [key: string]: string;
}>({
  "X-DEVICE-OS": "WEB",
  "X-APP-VERSION": "10000",
  "Content-Type": "application/json",
  "X-DEVICE-INFO": "WEB",
  "X-CLIENT-TYPE": "MSDK",
  "X-PLAN-TYPE": "BASIC",
});

function getCompleteHeaders(): HeadersInit {
  let completeHeaders = defaultHeaders();
  completeHeaders = {
    ...completeHeaders,
    [Header.HostOs]: getOS() ?? "UNKNOWN",
    [Header.RequestId]: newRequestId(),
  };

  return completeHeaders;
}

export const [initParams, setInitParams] = createSignal<SDKInitParams>(
  {} as any
);

export async function ssoTokenLogin(
  params: SDKInitParams
): Promise<SDKAuthData> {
  const url = new URL(`${config.baseUrl}/v1/auth/external/sso-token-login`);

  const response = await hubbleFetch(url.toString(), {
    method: "POST",
    body: JSON.stringify({
      token: params.token,
    }),
    headers: {
      [Header.RequestId]: newRequestId(),
      [Header.ContentType]: "application/json",
      [Header.AppVersion]: params.appVersion,
      [Header.ClientId]: params.clientId,
      [Header.ClientSecret]: params.clientSecret,
    },
  });

  setAuth(response);

  setDefaultHeaders({
    ...defaultHeaders(),
    [Header.DeviceId]: params.deviceId || newRequestId(),
    [Header.SessionId]: auth().sessionId,
    [Header.ClientId]: params.clientId,
    [Header.ClientSecret]: params.clientSecret,
    [Header.RequestId]: newRequestId(),
  });

  sdkEventManager.setIdentity(response.userDetails.userId);
  sdkEventManager.setClientId(params.clientId);
  attachUserIdToSentry(response.userDetails.userId);

  return response;
}

export async function fetchClientConfig(): Promise<ClientConfig> {
  try {
    const url = new URL(`${config.baseUrl}/v1/client/config`);
    const clientConfig = await hubbleFetch(url.toString(), {
      method: "GET",
      headers: getCompleteHeaders(),
    });

    return clientConfig;
  } catch (e) {
    if (e instanceof APIError) {
      if (e.code === 401) {
        localStorage.removeItem("sessionId");
        setAuth(() => {
          return {
            sessionId: null,
          } as any;
        });
        const headers = defaultHeaders();

        delete headers[Header.SessionId];
        setDefaultHeaders({
          ...headers,
        });
      }
    }

    throw e;
  }
}

export async function fetchV4Home(): Promise<V4HomeResponse> {
  try {
    const url = new URL(`${config.baseUrl}/v4/homepage`);
    const v4HomePage = await hubbleFetch(url.toString(), {
      method: "GET",
      headers: getCompleteHeaders(),
    });

    sdkEventManager.setIdentity(v4HomePage.user.id);
    attachUserIdToSentry(v4HomePage.user.id);

    return v4HomePage;
  } catch (e) {
    if (e instanceof APIError) {
      if (e.code === 401) {
        localStorage.removeItem("sessionId");
        setAuth(() => {
          return {
            sessionId: null,
          } as any;
        });
        const headers = defaultHeaders();

        delete headers[Header.SessionId];
        setDefaultHeaders({
          ...headers,
        });
      }
    }

    throw e;
  }
}

export async function isUserProfileComplete(
  sessionId: string
): Promise<boolean> {
  const url = new URL(`${config.baseUrl}/v1/user/profile`);
  return hubbleFetch(url.toString(), {
    method: "GET",
    headers: {
      ...getCompleteHeaders(),
      [Header.SessionId]: sessionId,
    },
  }).then((response) => {
    return response.firstName != null && response.email != null;
  });
}

export async function logout() {
  const url = new URL(`${config.baseUrl}/v1/auth/logout`);

  return hubbleFetch(url.toString(), {
    method: "POST",
    body: JSON.stringify({}),
    headers: {
      ...getCompleteHeaders(),
    },
  }).then((response) => {
    localStorage.removeItem("sessionId");
    clearUserFromSentry();
    setAuth(() => {
      return {
        sessionId: null,
      } as any;
    });

    const headers = defaultHeaders();

    delete headers[Header.SessionId];
    setDefaultHeaders({
      ...headers,
    });
    EventManager.logout();
  });
}

// @override
// Future<Result<SendOTPResult>> sendOTPViaSMS(String phoneNumber) async {
//   const path = 'v1/auth/otp/generate';
//   return http.post(
//       uri: Uri.parse('$baseUrl/$path').toString(),
//       body: {'phoneNumber': phoneNumber, 'medium': 'SMS'},
//       success: (json) => SendOTPResult.fromJson(json));
// }

export interface SendOTPResult {
  availableMediums: string[];
  otpToken: string;
}

export async function sendOTPViaSMS(
  phoneNumber: string
): Promise<SendOTPResult> {
  const url = new URL(`${config.baseUrl}/v1/auth/otp/generate`);
  return hubbleFetch(url.toString(), {
    method: "POST",
    body: JSON.stringify({ phoneNumber, medium: "SMS" }),
    headers: getCompleteHeaders(),
  });
}

export async function updateProfile(
  firstName: string,
  lastName: string,
  email: string,
  sessionId: string
): Promise<void> {
  const url = new URL(`${config.baseUrl}/v1/user/profile`);
  return hubbleFetch(url.toString(), {
    method: "PATCH",
    body: JSON.stringify({
      firstName,
      lastName,
      email,
    }),
    headers: {
      ...getCompleteHeaders(),
      [Header.SessionId]: sessionId,
    },
  });
}

//   @override
//   Future<Result<SubmitOTPResult>> submitOTP({
//     required String phoneNumber,
//     required String otp,
//     required String otpToken,
//     required bool autoFill,
//   }) async {
//     const path = 'v1/auth/otp/verify';
//     return http.post(
//         uri: Uri.parse('$baseUrl/$path').toString(),
//         body: {
//           'phoneNumber': phoneNumber,
//           'otp': otp,
//           'otpToken': otpToken,
//           'autoFill': autoFill
//         },
//         success: (json) => SubmitOTPResult.fromJson(json));
//   }

export interface SubmitOTPResult {
  sessionId: string;
}

export async function submitOTP(
  phoneNumber: string,
  otp: string,
  otpToken: string,
  autoFill: boolean
): Promise<SubmitOTPResult> {
  const url = new URL(`${config.baseUrl}/v1/auth/otp/verify`);
  return hubbleFetch(url.toString(), {
    method: "POST",
    body: JSON.stringify({ phoneNumber, otp, otpToken, autoFill }),
    headers: getCompleteHeaders(),
  });
}
