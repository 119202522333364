import { createSignal } from "solid-js";
import {
  GiftDetailsMinimalResponse,
  GiftVoucherDetailsResponse,
  GiftVoucherStatus,
} from "../types/gift";
import {
  CoinsTransactionData,
  CoinsTransactionsResponse,
} from "~/data/transaction";

export async function getMockGiftDetailsMinimalResponse(
  key: string
): Promise<GiftDetailsMinimalResponse> {
  await new Promise((resolve) => setTimeout(resolve, 2000));
  const data = {
    serialNo: "123456",
    productMetadata: {
      brandId: "01GMAVS2CHXR0XP1BZSTA9A44K",
      brandKey: "amazon",
      heroImageUrl:
        "https://assets.myhubble.money/brand-assets/hero-images-wp/amazon-hero.webp",
      deeplink: "https://google.com",
      tncResId: "voucher-tnc/amazon.md",
      brandName: "Amazon",
      cardBackgroundColor: "#FF9900",
      plainLogoUrl:
        "https://assets.myhubble.money/brand-assets/brand-logos/amazon-logo.png",
    },
    dateOfManufacture: "2021-09-01",
    expiry: "2021-09-30",
    amount: 5000,
    description: "You have been gifted endless choices of products",
    faqs: [
      {
        question: "What is a gift card?",
        answer: "Also known as brand vouchers",
      },
      {
        question: "Why should I use a gift card?",
        answer:
          "Gift cards help you get great value on your purchase with a brand. They simplify giving, help with budgeting, and are easily sent across distances, ensuring your thoughtful gesture brings joy and value.",
      },
      {
        question: "How to use a gift card?",
        answer:
          "To use a gift card, simply present it at the time of purchase in-store or enter the card’s unique code at checkout when shopping online. The amount of your purchase will be deducted from the card's balance.",
      },
    ],
  };

  let status: string | undefined = undefined;

  [
    "NOT_ACTIVATED",
    "DEACTIVATED",
    "ACTIVATED",
    "GENERATING",
    "GENERATED",
    "GENERATION_FAILED",
    "READY_TO_GENERATE",
    "EXPIRED",
  ].forEach((i) => {
    if (key.includes(i.toLowerCase())) {
      if (!status) {
        status = i;
      }
    }
  });

  return {
    status: status || "ACTIVATED",
    authMethods: key.includes("otp")
      ? ["PHONE_OTP", "NONE"]
      : ["NONE", "PHONE_OTP"],
    deliveryMethods: key.includes("whatsapp")
      ? ["WHATSAPP", "INSTANT"]
      : ["INSTANT", "WHATSAPP"],
    ...data,
    isAuthRequired: key.includes("auth"),
    delivery: {
      retryAvailable: key.includes("no_retry") ? false : true,
    },
    customer: key.includes("linked")
      ? {
          phoneNumber: "1234567890",
        }
      : null,
  };
}

const [fakePollCounter, setFakePollCounter] = createSignal(0);

export async function getMockGetGiftVoucherDetailsResponse(
  id: string
): Promise<GiftVoucherDetailsResponse> {
  let status = "GENERATED" as GiftVoucherStatus;
  if (id.includes("fakepoll")) {
    setFakePollCounter(fakePollCounter() + 1);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    status =
      fakePollCounter() % 3 === 0
        ? id.includes("fakepoll-f")
          ? "GENERATION_FAILED"
          : "GENERATED"
        : "GENERATING";
  } else {
    await new Promise((resolve) => setTimeout(resolve, 4000));
  }
  if (id.includes("api-error")) {
    throw new Error("API error");
  }
  return {
    status: status,
    voucher: {
      redemptionType: "PIN_NO_SECURED",
      cardNumber: "1234567890",
      pin: "1234",
      amount: 100,
      expiryDate: "2023-12-31",
    },
    customer: {
      phoneNumber: "1234567890",
    },
    productMetadata: {
      brandId: "123",
      brandKey: "brand-key",
      brandName: "Amazon voucher balance",
      heroImageUrl: "https://via.placeholder.com/150",
      // deeplink: "",
      deeplink: "https://deeplink.com",
      tncResId: "tnc-res-id",
      cardBackgroundColor: "#FF0000",
      plainLogoUrl: "https://via.placeholder.com/150",
    },
    delivery: {
      retryAvailable: true,
    },
  };
}

export function getMockTransactionsResponse(): CoinsTransactionsResponse {
  let dummyList: CoinsTransactionData[] = [
    {
      id: "1",
      amount: 100,
      dateTime: "2021-10-10T10:10:10Z",
      transactionType: "CREDIT",
      transactionStatus: "SUCCESS",
      remarks: "You earned 100 coins",
      expiresOn: "2021-10-10T10:10:10Z",
    },
    {
      id: "2",
      amount: 200,
      dateTime: "2021-10-10T10:10:10Z",
      transactionType: "DEBIT",
      transactionStatus: "SUCCESS",
      remarks: "You spent 200 coins",
      expiresOn: "2021-10-10T10:10:10Z",
    },
    {
      id: "3",
      amount: 300,
      dateTime: "2021-10-10T10:10:10Z",
      transactionType: "CREDIT",
      transactionStatus: "SUCCESS",
      remarks: "You earned 300 coins",
      expiresOn: "2021-10-10T10:10:10Z",
    },
    {
      id: "4",
      amount: 400,
      dateTime: "2021-10-10T10:10:10Z",
      transactionType: "DEBIT",
      transactionStatus: "SUCCESS",
      remarks: "You spent 400 coins",
      expiresOn: "2021-10-10T10:10:10Z",
    },
  ];

  return {
    data: dummyList,
  };
}
