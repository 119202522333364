export function formatNumberIndianStyle(number: number) {
  return new Intl.NumberFormat("en-IN").format(number);
}

export function toRupees(amount: number) {
  return `₹${toIndianNumber(amount)}`;
}

export function toIndianNumber(amount: number) {
  return formatNumberIndianStyle(amount);
}

export function maskPhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/\d{6}/, "******");
}
