export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "WINDOWS_PHONE";
  }

  if (/android/i.test(userAgent)) {
    return "ANDROID";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "IOS";
  }

  return "UNKNOWN";
}

export function getOS() {
  if (window === undefined) return "UNKNOWN";
  var uA = navigator.userAgent || navigator.vendor;
  if (
    /iPad|iPhone|iPod/.test(uA) ||
    (uA.includes("Mac") && "ontouchend" in document)
  )
    return "IOS";

  var i,
    os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"];
  for (i = 0; i < os.length; i++)
    if (new RegExp(os[i], "i").test(uA)) return os[i].toUpperCase();
}

export function isMobile() {
  return getMobileOperatingSystem() !== "UNKNOWN";
}

export function isIOS() {
  return getMobileOperatingSystem() === "IOS";
}

export function isAndroid() {
  return getMobileOperatingSystem() === "ANDROID";
}
