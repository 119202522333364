export default function RibbonLinear() {
  return (
    <svg
      width="113"
      height="4"
      viewBox="0 0 113 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.4999 2L49.5519 2.60948C48.2342 3.45653 46.5434 3.45653 45.2258 2.60948L43.3296 1.39052C42.012 0.543472 40.3212 0.543471 39.0036 1.39052L37.1074 2.60948C35.7898 3.45653 34.099 3.45653 32.7814 2.60948L30.8852 1.39052C29.5676 0.543472 27.8768 0.543471 26.5591 1.39052L24.663 2.60948C23.3453 3.45653 21.6545 3.45653 20.3369 2.60948L18.4408 1.39052C17.1231 0.543472 15.4323 0.543471 14.1147 1.39052L12.2185 2.60948C10.9009 3.45653 9.2101 3.45653 7.89247 2.60948L5.99631 1.39052C4.67868 0.543472 2.98788 0.543471 1.67025 1.39052L0.722168 2"
        stroke="inherit"
        stroke-linecap="round"
      />
      <circle cx="56.5" cy="2" r="2" fill="inherit" />
      <path
        d="M62.5001 2L63.4481 2.60948C64.7658 3.45653 66.4566 3.45653 67.7742 2.60948L69.6704 1.39052C70.988 0.543472 72.6788 0.543471 73.9964 1.39052L75.8926 2.60948C77.2102 3.45653 78.901 3.45653 80.2186 2.60948L82.1148 1.39052C83.4324 0.543472 85.1232 0.543471 86.4409 1.39052L88.337 2.60948C89.6547 3.45653 91.3455 3.45653 92.6631 2.60948L94.5592 1.39052C95.8769 0.543472 97.5677 0.543471 98.8853 1.39052L100.781 2.60948C102.099 3.45653 103.79 3.45653 105.108 2.60948L107.004 1.39052C108.321 0.543472 110.012 0.543471 111.33 1.39052L112.278 2"
        stroke="inherit"
        stroke-linecap="round"
      />
    </svg>
  );
}
