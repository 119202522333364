import { StarRow, coinIcon, ribbon, ribbonConfetti } from "~/assets/assets";
import { useModal } from "~/components/modal";
import { toIndianNumber } from "~/utils/number";
import useScript from "~/utils/hooks/use_script";
import { config } from "~/data/config";
import { Cookie } from "~/types";
import { getCookie } from "~/utils/client_cookie";
import RibbonLinear from "~/assets/svg_icons/ribbon_linear";

export type WelcomeMessageRouteProps = {
  coinsCredited: number;
  userName: string;
  title: string;
  subtitle: string;
  showDummy: boolean;
};

export default function WelcomeMessage(props: WelcomeMessageRouteProps) {
  const dummyData = {
    coinsCredited: 250,
    userName: "Hi Varun",
    title: "Happy Diwali",
    subtitle: "You have been gifted Rs.250",
  };
  const { setIsModalOn, updateModalContentGetter } = useModal()!;
  let ribbonConfettiElement: HTMLInputElement | undefined;
  const isClientAxis = getCookie(Cookie.ClientId) === config.axisClientId;

  useScript(
    "https://unpkg.com/@lottiefiles/lottie-player@0.3.0/dist/lottie-player.js",
    async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      try {
        // @ts-ignore
        ribbonConfettiElement?.play();
      } catch (e) {
        console.error(e);
      }
    }
  );

  function RibbonConfettiLottie() {
    return (
      <lottie-player
        ref={ribbonConfettiElement}
        src={ribbonConfetti}
        autoplay={false}
        loop={false}
      ></lottie-player>
    );
  }

  return (
    <div class="relative flex w-full flex-col gap-4 bg-baseTertiaryLight md:rounded-2xl md:border md:border-baseTertiaryMedium">
      <div class="relative m-auto -mt-[20px] h-[280px] w-[356px]">
        <div class="absolute left-0 top-2 mt-3 h-full w-full bg-loginRNRConcCircles bg-contain bg-center bg-no-repeat"></div>
        <div class="relative z-10 flex h-full w-full flex-col items-center justify-center">
          <div class="absolute left-2/4 top-[40%] flex h-[106px] w-[106px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full ">
            <img src={coinIcon} class="w-full" alt={""} />
          </div>
          <div class="absolute left-2/4 top-[85%] flex w-full -translate-x-1/2 -translate-y-1/2  flex-col items-center justify-center">
            <div class=" flex flex-col items-center justify-center">
              <div class="text-h2 text-rewardsYellow">
                +
                {toIndianNumber(
                  props.showDummy
                    ? dummyData.coinsCredited
                    : props.coinsCredited
                )}
              </div>
              <div class="pt-1 text-normal text-textDark">Coins credited</div>
            </div>
            <div class="my-5 w-28 fill-rewardsYellow stroke-rewardsYellow darkTheme:fill-[#313538] darkTheme:stroke-[#313538] ">
              <RibbonLinear />
            </div>
            <div class="pb-1 text-subtitleSmall text-basePrimaryDark">
              {props.showDummy
                ? dummyData.userName.toUpperCase()
                : props.userName.toUpperCase()}
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center justify-start">
        <div class="flex items-center justify-center gap-2 ">
          <StarRow
            starSizes={[2, 3.5]}
            fillColor="#E47B00"
            isClientAxis={() => isClientAxis}
          />
          <p
            class="font-sacramento text-h1 font-normal leading-[52px] tracking-normal"
            classList={{
              "text-axisPink": isClientAxis,
              "text-orange": !isClientAxis,
            }}
          >
            {isClientAxis ? "Congratulations" : props.title}
          </p>
          <StarRow
            starSizes={[3.5, 2]}
            fillColor="#E47B00"
            isClientAxis={() => isClientAxis}
          />
        </div>
        <span
          class="mb-5 max-w-[60%] text-center text-normal  text-basePrimaryDark "
          classList={{
            italic: !isClientAxis,
          }}
        >
          {props.showDummy ? dummyData.subtitle : props.subtitle}
        </span>

        <div class="w-full px-6 py-4">
          <button
            type="submit"
            class="my-2 w-full rounded-full bg-basePrimaryDark py-3 text-f16Bold text-baseTertiaryLight disabled:bg-gray-400"
            onclick={(event) => {
              setIsModalOn(false);
              updateModalContentGetter(() => <></>);
              event.stopPropagation();
            }}
          >
            Yay!
          </button>
        </div>
      </div>
      <div class="pointer-events-none absolute left-0 right-0 top-0 flex scale-150 ">
        <RibbonConfettiLottie />
      </div>
    </div>
  );
}
