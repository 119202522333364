import { Accessor, createEffect, createSignal } from "solid-js";

export type JobState = "idle" | "running" | "error" | "success";
export type Job<T> = () => Promise<T>;
export type JobCallback<T> = (data: T) => Promise<void>;
export type JobErrorCallback = (error: any) => void;

export function createJob<T>(props: {
  successCallback?: JobCallback<T>;
  initialJob?: Job<T>;
  errorCallback?: JobErrorCallback;
}): JobWrapper<T> {
  const [jobState, setJobState] = createSignal<JobState>("idle");
  const [job, setJob] = createSignal<{ job: Job<T> | null }>({
    job: props.initialJob ?? null,
  });
  const [jobResult, setJobResult] = createSignal<T | null>(null);
  var isRunRequested = false;

  var _jobState = jobState();
  createEffect(() => {
    _jobState = jobState();
  });

  createEffect(() => {
    const callback = job();

    // don't run when created
    if (!!props.initialJob) {
      props.initialJob = undefined;
      return;
    }

    if (callback.job && isRunRequested) {
      isRunRequested = false;
      (async () => {
        if (_jobState == "running") {
          return;
        }

        setJobState("running");
        try {
          const result: T = await callback.job!();
          setJobResult(() => result);
          setJobState("success");
          await props.successCallback?.(result);
        } catch (e) {
          setJobState("error");
          props.errorCallback?.(e);
        }
      })();
    }
  });

  function run() {
    if (_jobState == "running") {
      return;
    }

    const callback = job();

    if (callback.job) {
      isRunRequested = true;
      setJob({
        job: callback.job,
      });
    }
  }

  return {
    jobState,
    setJob: (j: Job<T>) => {
      setJob({ job: j });
    },
    jobResult,
    run,
  };
}

export type JobWrapper<T> = {
  jobState: Accessor<JobState>;
  setJob: (j: Job<T>) => void;
  jobResult: Accessor<T | null>;
  run: () => void;
};
