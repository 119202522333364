import { createEffect, createSignal } from "solid-js";
import { toRupees } from "~/utils/number";
import { BackButton, ButtonRegular } from "~/widgets/button";
import { Spacer } from "~/widgets/spacer";
import { createJob } from "~/utils/job";
import { makeUPICollectRequest } from "~/data/products";
import { showSnackBar } from "~/shared_states/snackbar";

export function UpiIdInputScreen({
  amount,
  orderId,
  onCollectRequestSent,
}: {
  amount: number;
  orderId: string;
  onCollectRequestSent: () => void;
}) {
  const [isUpiIdValid, setIsUpiIdValid] = createSignal(true);
  const [upiId, setUpiId] = createSignal("");
  const [showErrorMessage, setShowErrorMessage] = createSignal(false);
  const [isJobRunning, setIsJobRunning] = createSignal(false);

  function upiIdValidator({ upiId }: { upiId: string }) {
    const _validUPI =
      /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+/;
    return _validUPI.test(upiId);
  }

  createEffect(() => {
    setIsUpiIdValid(upiIdValidator({ upiId: upiId() }));
  });

  return (
    <div class="flex grow flex-col  px-6">
      <BackButton />

      <Spacer height={74} />
      <div class="text-h3">Complete payment</div>
      <div class="text-h5">Amount: {toRupees(amount)}</div>
      <Spacer height={18} />
      <div class="rounded-xl border border-baseTertiaryDark  px-4 py-[13px]">
        <input
          class="w-full  outline-none"
          placeholder="Enter your UPI ID"
          value={upiId()}
          onInput={(e) => setUpiId(e.currentTarget.value.trim())}
        />
      </div>
      <Spacer height={8} />
      <div
        class="text-small text-errorDark opacity-0 transition-opacity"
        classList={{
          "opacity-100": !isUpiIdValid() && showErrorMessage(),
        }}
      >
        {upiId().length > 0
          ? "UPI ID is invalid"
          : "Please enter a valid UPI ID"}
      </div>
      <Spacer height={8} />
      <ButtonRegular
        isLoading={isJobRunning()}
        isEnabled={true}
        onClick={async () => {
          if (!isUpiIdValid()) {
            setShowErrorMessage(true);
            return;
          }
          try {
            setIsJobRunning(true);
            const result = await makeUPICollectRequest(orderId, upiId());
            setIsJobRunning(false);
            onCollectRequestSent();
          } catch (e: any) {
            setIsJobRunning(false);
            showSnackBar({
              message: e.message || "Failed to send UPI collect request",
              level: "error",
            });
          }
        }}
      >
        Continue
      </ButtonRegular>
    </div>
  );
}
